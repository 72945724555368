import React, { useContext } from 'react';

import { Button } from 'common/components/Button';
import { Col } from 'common/components/Layout';
import { Tooltip } from 'common/components/Tooltip';
import { SubscriptionsContext } from 'contexts/subscriptionsContext';

export const PollingSwitch = () => {
  const { isSubscriptionsOn, setIsSubscriptionsOn } =
    useContext(SubscriptionsContext);

  return (
    <>
      <Button
        text={`Switch to ${isSubscriptionsOn ? 'polling' : 'subscriptions'}`}
        onClick={() => setIsSubscriptionsOn(!isSubscriptionsOn)}
      />
      <Col marginL={10}>
        <Tooltip text="Subscriptions is constantly receiving information from the server only if there is a change. Polling is constantly asking for a change in pre-defined intervals." />
      </Col>
    </>
  );
};
