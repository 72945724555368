import React, {
  createContext,
  useCallback,
  useEffect,
  useState,
  useContext,
} from 'react';

import { useApolloClient } from '@apollo/client';
import * as Sentry from '@sentry/browser';

import {
  MeDocument,
  MeQuery,
  FacilityFragment,
} from 'codegen/generated/graphql';
import { setFacilityTz } from 'common/utils/time';
import { OperationHours } from 'manager/utils/types';
import {
  setNewStorageFacilityId,
  getStorageFacilityId,
  areBothStorageFacilityIdsSet,
} from 'utils';
import { auth } from 'utils/firebase';

import { getApolloLink } from './utils/apollo';

export type Me = NonNullable<MeQuery['me']> | null;
export type ContextFacility = Omit<FacilityFragment, 'operationHours'> & {
  operationHours: OperationHours[];
};

export type AuthContextType = AuthState & {
  logout: () => Promise<void>;
  setAuthState: React.Dispatch<React.SetStateAction<AuthState>>;
  setFacilityById: (facilityId: string) => void;
  refetchFacilitiesData: (facilityId?: string) => Promise<void>;
};

export interface AuthState {
  user: Me;
  loading: boolean;
  selectedFacility: ContextFacility | null;
}

const getCurrentFacilityAndSetLocalStorage = (
  facilities: ContextFacility[],
  facilityId?: string,
) => {
  if (!facilities.length) return null;

  const storageFacilityId = facilityId ?? getStorageFacilityId();

  if (!areBothStorageFacilityIdsSet()) {
    setNewStorageFacilityId(storageFacilityId || facilities[0].id);
  }

  const foundFacility = facilities.find(
    (facility) => facility.id === storageFacilityId,
  );

  if (foundFacility) return foundFacility;

  return facilities[0];
};

type ProviderProps = {
  children: React.ReactNode;
};

export const AuthProvider = ({ children }: ProviderProps) => {
  const client = useApolloClient();
  const [state, setState] = useState<AuthState>({
    user: null,
    loading: true,
    selectedFacility: null,
  });
  const resetState = useCallback(() => {
    setState({
      user: null,
      loading: false,
      selectedFacility: null,
    });
  }, []);

  const resetLink = () => client.setLink(getApolloLink());

  const setFacilityById = async (facilityId: string) => {
    const facility =
      state.user?.facilities.find((f) => f.id === facilityId) || null;

    if (facility) {
      setFacilityTz(facility.timeZone);
      setNewStorageFacilityId(facility.id);
    }

    setState({
      ...state,
      selectedFacility: facility,
    });

    resetLink();
    await client.resetStore();
  };

  const initializeFacility = async (facilityId?: string) => {
    try {
      const { data } = await client.query<MeQuery>({
        fetchPolicy: 'network-only',
        query: MeDocument,
      });

      if (!data?.me) return resetState();

      const facility = getCurrentFacilityAndSetLocalStorage(
        data.me.facilities,
        facilityId,
      );

      if (!facility) {
        return auth.signOut();
      }

      if (facility.id !== state.selectedFacility?.id) {
        resetLink();
      }

      setFacilityTz(facility.timeZone);

      setState({
        user: data?.me,
        loading: false,
        selectedFacility: facility,
      });
    } catch {
      return resetState();
    }
  };

  useEffect(() => {
    auth.onAuthStateChanged(async (user) => {
      if (!user) return resetState();

      setState((prevState) => ({ ...prevState, loading: true }));

      await initializeFacility();
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [client, resetState]);

  if (state.user) {
    Sentry.configureScope((scope) => {
      scope.setUser({ user: state.user?.id, name: state.user?.name });
    });
  }

  return (
    <AuthContext.Provider
      value={{
        user: state.user,
        loading: state.loading,
        selectedFacility: state.selectedFacility,
        logout: async () => {
          await auth.signOut();
          await client.clearStore();
          localStorage.removeItem('facilityId');
          sessionStorage.removeItem('facilityId');
          resetLink();
        },
        setFacilityById,
        setAuthState: setState,
        refetchFacilitiesData: initializeFacility,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const useAuthContext = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuthContext must be used within a AuthProvider');
  }

  return context;
};
