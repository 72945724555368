import React from 'react';
import { createPortal } from 'react-dom';

import { usePortal } from 'common/utils/portal';

interface Props {
  id: string;
  children?: React.ReactNode;
}

export const Portal = ({ id, children }: Props) => {
  const target = usePortal(id);

  return createPortal(children, target);
};
