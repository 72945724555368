import React, { useRef, useState } from 'react';

import {
  useUpdateKitchenMenuItemInventoryMutation,
  InventoryStatus,
} from 'codegen/generated/graphql';
import { Icon, IconSize } from 'common/components/Icon';
import { Row, Col } from 'common/components/Layout';
import { Text } from 'common/components/Text';

import { StatusLabel } from '../helpers';

import {
  BadgeInput,
  RedCloseCircleIcon,
  GreenCheckCircleIcon,
} from '../styles';

interface Props {
  id: string;
  inventoryCountQL: number | null;
  inventoryStatus: InventoryStatus;
  enableInventory: boolean;
  isCustomerSupport?: boolean;
  isActive: boolean;
}

export const InventoryCount = ({
  id,
  inventoryStatus,
  enableInventory,
  inventoryCountQL,
  isCustomerSupport,
  isActive,
}: Props) => {
  const [inventoryCount, setInventoryCount] = useState(inventoryCountQL);
  const inputRef = useRef<HTMLInputElement>(null);

  const [updateInventoryCount, { loading }] =
    useUpdateKitchenMenuItemInventoryMutation();

  const onKeyDown = async (e: React.KeyboardEvent<HTMLInputElement>) => {
    // arrow down/up keys
    if (e.which === 38 || e.which === 40) {
      e.preventDefault();
    }

    // escape or enter
    if (e.which === 27) {
      inputRef.current?.blur();

      return false;
    }

    if (!enableInventory || !inventoryCount || inventoryCount < 0) {
      inputRef.current?.blur();

      return false;
    }

    if (e.which === 13) {
      await updateInventoryCountQL(inventoryCount);
      inputRef.current?.blur();

      return false;
    }

    return false;
  };

  const updateInventoryCountQL = async (value: number) => {
    await updateInventoryCount({
      variables: {
        id,
        inventoryCount: value,
      },
    });
    setInventoryCount(value);
  };

  return (
    <Row alignItems="center">
      <Col alignItems="center">
        {inventoryStatus === InventoryStatus.SoldOut ? (
          <>
            <RedCloseCircleIcon />
            <Text marginLeft="sm" marginRight="md">
              Sold Out
            </Text>
          </>
        ) : (
          <>
            <GreenCheckCircleIcon />
            <Text marginLeft="sm" marginRight="md">
              {StatusLabel[inventoryStatus]}
            </Text>
          </>
        )}
        {!isCustomerSupport && enableInventory && (
          <Icon
            onClick={async () => {
              if (inventoryCount && inventoryCount > 0 && !loading)
                await updateInventoryCountQL(inventoryCount - 1);
            }}
            name="remove"
            size={IconSize.SMALL}
          />
        )}
        {enableInventory && (
          <Col marginX="sm">
            <BadgeInput
              disabled={loading || isCustomerSupport}
              ref={inputRef}
              pattern="[0-9]*"
              min="0"
              onChange={(e) => setInventoryCount(Number(e.target.value))}
              onClick={() => inputRef.current?.select()}
              onFocus={(e) => e.target.select()}
              onBlur={() => setInventoryCount(inventoryCountQL)}
              onKeyDown={onKeyDown}
              value={inventoryCount?.toString()}
            />
          </Col>
        )}
        {!isCustomerSupport && enableInventory && (
          <Icon
            onClick={async () => {
              if (!loading)
                await updateInventoryCountQL((inventoryCount || 0) + 1);
            }}
            name="add"
            size={IconSize.SMALL}
          />
        )}
      </Col>
    </Row>
  );
};
