import styled, {
  FontFamily,
  FontSizes,
  FontWeight,
  LineHeight,
} from 'styled-components';

import { themeColors } from 'common/theme/constants';

import {
  font,
  fontSize,
  fontWeight,
  color,
  lineHeight,
  TextAlignProps,
  textAlign,
  TextTransformProps,
  textTransform,
  fontStyle,
  FontStyleProps,
  marginB,
  marginR,
  marginL,
  marginT,
  truncateText,
  ColorProps,
  SpacingType,
  AlignSelfProps,
  alignSelf,
  letterSpacing as spacing,
  WhiteSpaceProps,
  whitespace,
} from '../../utils/styleHelpers';

export interface StyledTextProps {
  fontFamily?: keyof FontFamily;
  fontSize?: keyof FontSizes;
  fontWeight?: keyof FontWeight;
  color?: ColorProps;
  lineHeight?: keyof LineHeight;
  textAlign?: TextAlignProps;
  alignSelf?: AlignSelfProps;
  textTransform?: TextTransformProps;
  fontStyle?: FontStyleProps;
  spaceAfter?: SpacingType;
  marginRight?: SpacingType;
  marginLeft?: SpacingType;
  marginTop?: SpacingType;
  whitespace?: WhiteSpaceProps;
  truncateText?: boolean;
  letterSpacing?: string;
  'aria-label'?: string;
  flex?: string;
  displayFlex?: boolean;
  strikeThrough?: boolean;
}

export const StyledText = styled.p<StyledTextProps>(
  ({
    fontFamily = 'primary',
    fontSize: size = 'body',
    fontWeight: weight = 'normal',
    lineHeight: height = 'normal',
    color: col = themeColors.primaryText,
    textAlign: align = 'left',
    alignSelf: alignS = 'auto',
    textTransform: transform = 'initial',
    fontStyle: style = 'initial',
    spaceAfter = 0,
    marginRight = 0,
    marginLeft = 0,
    marginTop = 0,
    whitespace: whiteSpace = 'normal',
    truncateText: truncate,
    letterSpacing,
    flex,
    displayFlex,
    strikeThrough,
  }) => `
  margin: 0;
  ${font(fontFamily)}
  ${fontSize(size)}
  ${fontWeight(weight)}
  ${color(col)}
  ${lineHeight(height)}
  ${textAlign(align)}
  ${alignSelf(alignS)}
  ${textTransform(transform)}
  ${fontStyle(style)}
  ${marginB(spaceAfter)}
  ${marginR(marginRight)}
  ${marginL(marginLeft)}
  ${marginT(marginTop)}
  ${whitespace(whiteSpace)}
  ${truncate ? truncateText() : ''}
  ${letterSpacing ? spacing(letterSpacing) : ''}
  ${flex ? `flex: ${flex}` : ''}
  ${displayFlex ? 'display: flex !important' : ''};
  ${strikeThrough ? 'text-decoration: line-through' : ''}
`,
);
