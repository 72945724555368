import styled from 'styled-components';

import {
  background,
  radius,
  padding,
  position,
  shadow,
} from 'common/utils/styleHelpers';

import { Props } from './types';

export const SwitchWrapper = styled.div<Props>(
  ({
    isActive,
    activeColor = 'primaryGreen',
    negativeColor = 'primaryRed',
    disabled,
  }) => `
  ${background(isActive ? activeColor : negativeColor)}
  ${padding(2)}
  ${radius('large')}
  ${position('relative')}
  ${disabled ? `${background(negativeColor)}` : 'cursor: pointer;'}
  width: 44px;
  height: 22px;
  transition: all 0.2s;

  ${SwitchButton} {
    left: ${isActive ? '23px' : '2px'};
  }
`,
);

export const SwitchButton = styled.div(
  () => `
  ${background('white')}
  ${radius('half')}
  ${position('absolute')}
  ${shadow('popover')}
  top: 2px;
  width: 18px;
  height: 18px;
  transition: all 0.2s;
`,
);
