export const phoneRegExp = /^(\([0-9]{3}\)\s*|[0-9]{3}\-)[0-9]{3}-[0-9]{4}$/;

export const errorMessage = (e: unknown) => {
  let message;
  if (e instanceof Error) message = e.message;
  else message = String(e);

  return message;
};

export const parseJsonSilently = (value: string) => {
  try {
    return JSON.parse(value);
  } catch (e) {
    return null;
  }
};
