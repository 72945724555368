import styled from 'styled-components';

import { themeColors } from 'common/theme/constants';
import {
  padding,
  display,
  background,
  radius,
  alignItems,
  justifyContent,
  border,
  borderColor,
} from 'common/utils/styleHelpers';

export const Wrapper = styled.div(
  ({ isActive }: { isActive?: boolean }) => `
    ${display('flex')}
    ${alignItems('center')}
    ${background('white')}
    ${radius('small')}
    ${padding('md')}
    ${justifyContent('space-between')}
    ${border(2)};
    ${isActive ? borderColor(themeColors.dark) : borderColor('deepBeige')};
    height: 55px;
    width: 100%;
    cursor: pointer;
`,
);
