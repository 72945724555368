import React from 'react';

import { Badge } from 'common/components/Badge';
import { ActionMenu } from 'manager/components/ActionMenu';

import { FacilityWithKitchens } from '../../containers/Promos/PromoList/utils';

import { Container } from './styles';

type Props = {
  values?: string[];
  list?: FacilityWithKitchens[];
};

export const MultiBadge: React.FC<Props> = ({ values, list }) => {
  return (
    <Container>
      {values ? (
        <>
          {values.slice(0, 5).map((value, key) => (
            <Badge
              key={key}
              text={value}
              textColor="black"
              backgroundColor="gray"
            />
          ))}
          {values.length > 5 && (
            <ActionMenu
              inline
              actions={values
                .slice(5, values.length)
                .map((value) => ({ label: value }))}
              badge={`+${values.length - 5}`}
            />
          )}
        </>
      ) : (
        <>
          {list &&
            list.map((value) => (
              <ActionMenu
                reverseList
                key={value.id}
                actions={value.kitchens.map((name: any) => ({
                  label: name,
                }))}
                badge={value.name}
              />
            ))}
        </>
      )}
    </Container>
  );
};
