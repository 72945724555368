import React from 'react';
import { UsePaginationInstanceProps } from 'react-table';

import { Row, Col } from 'common/components/Layout';
import { Text } from 'common/components/Text';

import { Button, ButtonIcon, Pagination } from '../styles';

interface Props {
  pageIndex: number;
  onPageChange?: (index: number) => void;
}

export const TablePagination = ({
  pageIndex,
  onPageChange,
  pageOptions,
  gotoPage,
  previousPage,
  nextPage,
  canPreviousPage,
  canNextPage,
  pageCount,
}: Omit<UsePaginationInstanceProps<any>, 'page' | 'setPageSize'> & Props) => (
  <Row>
    <Pagination>
      <Col marginR="sm">
        <Text>
          Page{' '}
          <strong>
            {pageOptions.length !== 0 ? pageIndex + 1 : 0} of{' '}
            {pageOptions.length}
          </strong>
        </Text>
      </Col>
      <Button
        onClick={() => {
          gotoPage(0);
          onPageChange?.(0);
        }}
        disabled={!canPreviousPage}
      >
        <ButtonIcon name="first_page" />
      </Button>
      <Button
        onClick={() => {
          previousPage();
          onPageChange?.(pageIndex - 1);
        }}
        disabled={!canPreviousPage}
      >
        <ButtonIcon name="chevron_left" />
      </Button>
      <Button
        onClick={() => {
          nextPage();
          onPageChange?.(pageIndex + 1);
        }}
        disabled={!canNextPage}
      >
        <ButtonIcon name="chevron_right" />
      </Button>{' '}
      <Button
        onClick={() => {
          gotoPage(pageCount - 1);
          onPageChange?.(pageCount - 1);
        }}
        disabled={!canNextPage}
      >
        <ButtonIcon name="last_page" />
      </Button>
    </Pagination>
  </Row>
);
