import { SearchMenuItem } from 'manager/utils/types';

export const enumValueToReadable = (value: string) => {
  const lowerCased = value.replaceAll('_', ' ').toLowerCase();

  return lowerCased[0].toUpperCase() + lowerCased.slice(1);
};

//formats phone number input into (xxx) xxx-xxxx format
export const formatPhoneNumber = (value: string) => {
  if (!value) {
    return value;
  }

  const currentValue = value.replace(/[^\d]/g, '');
  const cvLength = currentValue.length;

  if (cvLength < 4) {
    return currentValue;
  }

  if (cvLength < 7) {
    return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3)}`;
  }

  return `(${currentValue.slice(0, 3)}) ${currentValue.slice(
    3,
    6,
  )}-${currentValue.slice(6, 10)}`;
};

export const removeExistingItems = (
  resultArr: SearchMenuItem[],
  subArray: SearchMenuItem[],
) => {
  const subIds = subArray.map((item) => item.id);

  return resultArr.filter((item) => !subIds.includes(item.id));
};

export const formatCartUpsellItems = (
  items: { id: string; upsellItem: SearchMenuItem }[],
) => {
  return items.map((item) => ({
    ...item.upsellItem,
  }));
};

export const phoneRegExp = /^(\([0-9]{3}\)\s*|[0-9]{3}\-)[0-9]{3}-[0-9]{4}$/;
