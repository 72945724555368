import * as Yup from 'yup';

import { IsGrantedArgs } from '../../../contexts/AccessControlContext/accessControlContext';
import { ContextFacility } from 'authContext';
import {
  FacilityTaxFragment,
  FeeTypeEnum,
  UserRole,
} from 'codegen/generated/graphql';
import { Action, Resource } from 'router/AccessControlConfig';
import { Routes } from 'router/routes';

export const FACILITY_SETTINGS_NAV_OPTIONS: Record<
  string,
  { label: string; value: string; resource?: Resource }
> = {
  profile: {
    label: 'Profile',
    value: Routes.FACILITY_PROFILE.path,
    resource: Resource.FacilitySettingsProfile,
  },
  orderMethodsOverride: {
    label: 'Order Methods Override',
    value: Routes.ORDER_METHODS_OVERRIDES.path,
    resource: Resource.FacilitySettingsOrderMethodOverrides,
  },
  operationHours: {
    label: 'Operation Hours',
    value: Routes.FACILITY_OPERATION_HOURS.path,
    resource: Resource.FacilitySettingsOperationHours,
  },
  brandsSorting: {
    label: 'Brands Sorting',
    value: Routes.BRANDS_REORDERING.path,
  },
  taxCalculations: {
    label: 'Tax Calculations',
    value: Routes.FACILITY_TAX_CALCULATIONS.path,
    resource: Resource.FacilitySettingsTaxCalculations,
  },
  platforms: { label: 'Platforms', value: Routes.FACILITY_PLATFORMS.path },
};

const roleOptions: Partial<
  Record<UserRole, Array<keyof typeof FACILITY_SETTINGS_NAV_OPTIONS>>
> = {
  [UserRole.RestaurantOwner]: ['profile', 'operationHours'],
  [UserRole.FacilityAdmin]: [
    'profile',
    'orderMethodsOverride',
    'operationHours',
    'brandsSorting',
    'platforms',
  ],
  [UserRole.CustomerSupport]: ['platforms'],
};

export const getFacilitySettingsNavRoutes = (
  userRoles: UserRole[] | undefined,
) => {
  const options = FACILITY_SETTINGS_NAV_OPTIONS;
  if (!userRoles) return [options.profile];

  const userRoleOptions = roleOptions[userRoles[0]];

  return userRoleOptions
    ? userRoleOptions.map((optionKey) => options[optionKey])
    : Object.values(options);
};

export const getFacilitySettingsNavRouterByGrant = (
  userRoles: UserRole[] | undefined,
  isGranted: (args: IsGrantedArgs) => boolean,
) => {
  return getFacilitySettingsNavRoutes(userRoles).filter(({ resource }) =>
    !resource ? true : isGranted({ resource, action: Action.READ }),
  );
};

export const SUPPORTED_FILE_TYPES = [
  'image/svg',
  'image/svg+xml',
  'image/png',
  'image/jpeg',
];

export const getTaxCalculationsInitialValues = (
  facility: ContextFacility | null,
): FacilityTaxFragment => {
  return {
    id: facility?.id ?? '',
    isConsumerServiceFeeTaxable: facility?.isConsumerServiceFeeTaxable ?? true,
    isCateringServiceFeeTaxable: facility?.isCateringServiceFeeTaxable ?? true,
    isConsumerDeliveryFeeTaxable:
      facility?.isConsumerDeliveryFeeTaxable ?? true,
    isCateringDeliveryFeeTaxable:
      facility?.isCateringDeliveryFeeTaxable ?? true,
    cateringDeliveryFee: facility?.cateringDeliveryFee ?? 0,
    cateringDeliveryFeeTaxRate: facility?.cateringDeliveryFeeTaxRate ?? 0,
    cateringServiceFee: facility?.cateringServiceFee ?? 0,
    cateringServiceFeeTaxRate: facility?.cateringServiceFeeTaxRate ?? 0,
    consumerDeliveryFee: facility?.consumerDeliveryFee ?? 0,
    consumerDeliveryFeeTaxRate: facility?.consumerDeliveryFeeTaxRate ?? 0,
    consumerServiceFee: facility?.consumerServiceFee ?? 0,
    consumerServiceFeeTaxRate: facility?.consumerServiceFeeTaxRate ?? 0,
    taxRate: facility?.taxRate ?? 0,
    consumerServiceFeeType:
      facility?.consumerServiceFeeType ?? FeeTypeEnum.Percentage,
    cateringServiceFeeType:
      facility?.cateringServiceFeeType ?? FeeTypeEnum.Percentage,
  };
};

export const taxCalculationsValidationSchema = Yup.object().shape({
  taxRate: Yup.string().trim().required('Tax rate is required'),
  cateringDeliveryFee: Yup.string().trim().required('Tax rate is required'),
  cateringDeliveryFeeTaxRate: Yup.string()
    .trim()
    .when('isCateringDeliveryFeeTaxable', {
      is: true,
      then: Yup.string().required('Catering delivery fee is required'),
    }),
  cateringServiceFee: Yup.string()
    .required('Catering service fee is required')
    .test(
      'isNumber',
      'Catering service fee must be a positive number',
      (value) =>
        !!value && !isNaN(sanitizeFees(value)) && sanitizeFees(value) >= 0,
    ),
  cateringServiceFeeTaxRate: Yup.string()
    .trim()
    .when('isCateringServiceFeeTaxable', {
      is: true,
      then: Yup.string().required('Catering service tax rate is required'),
    }),
  consumerDeliveryFee: Yup.string()
    .trim()
    .required('Comsumer delivery fee is required'),
  consumerDeliveryFeeTaxRate: Yup.string()
    .trim()
    .when('isConsumerDeliveryFeeTaxable', {
      is: true,
      then: Yup.string().required('Consumer delivery tax rate is required'),
    }),
  consumerServiceFee: Yup.string()
    .required('Consumer service fee is required')
    .test(
      'isNumber',
      'Consumer service fee must be a positive number',
      (value) =>
        !!value && !isNaN(sanitizeFees(value)) && sanitizeFees(value) >= 0,
    ),
  consumerServiceFeeTaxRate: Yup.string()
    .trim()
    .when('isConsumerServiceFeeTaxable', {
      is: true,
      then: Yup.string().required('Consumer service tax rate is required'),
    }),
});

export const yesNoOptions = [
  { label: 'Yes', value: true },
  { label: 'No', value: false },
];

export const sanitizeFees = (fee?: string | number) => {
  if (typeof fee === 'number') return fee;

  return Number(fee?.replace(/[%$]/g, ''));
};
