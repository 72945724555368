import {
  ApolloClient,
  ApolloLink,
  InMemoryCache,
  createHttpLink,
  split,
  NormalizedCacheObject,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { getMainDefinition } from '@apollo/client/utilities';
import fetch from 'cross-fetch';

import { FACILITY_TZ, wait } from 'common/utils/time';

import { auth } from './firebase';
import { logToGCP } from './logging';
import { getScreenId, setSubscriptionConnected } from './screenSetup';
import { getTenantFromStorage } from './tenant';
import { WebSocketLink } from './webSocketLink';

import { getStorageFacilityId } from '.';

const getHeaders = async () => {
  const token = await auth.currentUser?.getIdToken();

  return {
    headers: {
      authorization: token ? `Bearer ${token}` : '',
      platform: 'admin',
      facility_id: getStorageFacilityId() || '',
      timezone: FACILITY_TZ,
      screenId: getScreenId() || undefined,
      tenant_id: getTenantFromStorage()?.id,
    },
  };
};

const authLink = setContext(getHeaders);

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_API_URL || 'http://localhost:4000/graphql',
  fetch,
});

let wsLink: WebSocketLink;
let wsLinkLostConnection = false;

let apolloClient: ApolloClient<NormalizedCacheObject>;

const getWsLink = () => {
  if (wsLink) {
    wsLink.dispose();
  }

  wsLink = new WebSocketLink({
    url: process.env.REACT_APP_API_WS_URL || 'ws://localhost:4000/graphql',
    connectionParams: getHeaders,
    keepAlive: 30 * 1000,
    lazy: false,
    retryAttempts: Infinity, // try to reconnect infinitely
    shouldRetry: () => true, // continue reconnecting even in case of fatal errors
    retryWait: async () => {
      await wait(5000);
    },
    on: {
      connected: () => {
        if (apolloClient && wsLinkLostConnection) {
          apolloClient.resetStore(); // FIXME: this is resetting the whole cache on every reconnection, do want to do this for the whole store?
        }
        wsLinkLostConnection = false;
        const screenId = getScreenId();
        setSubscriptionConnected(true);
        logToGCP(`Client - Subscription connected for screen ${screenId}`, {
          screenId,
        });
      },
      closed: () => {
        wsLinkLostConnection = true;
        const screenId = getScreenId();
        setSubscriptionConnected(false);
        logToGCP(`Client - Subscription disconnected for screen ${screenId}`, {
          screenId,
        });
      },
    },
  });

  return wsLink;
};

const getSplitLink = () =>
  split(
    // split based on operation type
    ({ query }) => {
      const definition = getMainDefinition(query);

      return (
        definition.kind === 'OperationDefinition' &&
        definition.operation === 'subscription'
      );
    },
    getWsLink(),
    httpLink,
  );

export const getApolloLink = () => {
  return ApolloLink.from([authLink as any, getSplitLink()]);
};

export const initApollo = () => {
  apolloClient = new ApolloClient({
    link: getApolloLink(),
    cache: new InMemoryCache({
      dataIdFromObject: (data) => {
        // FIXME: remove dependency of length 10 ⬇️!!!
        // Handles order now timeslot
        if (
          data.__typename === 'Timeslot' &&
          typeof data.id === 'string' &&
          (data.id.length === 10 ||
            (data.id.length === 17 && data.id.endsWith('pickup')))
        ) {
          return undefined;
        }

        if (!data.id || !data.__typename) {
          return undefined;
        }

        return `${data.__typename}:${data.id}`;
      },
      typePolicies: {
        // fix overwriting of menu filters
        MenuFilter: {
          merge(existing, incoming) {
            return { ...existing, ...incoming };
          },
        },
        KitchenMenuItem: {
          fields: {
            mealPackItems: {
              merge: false,
            },
          },
        },
      },
    }),
  });

  return apolloClient;
};

export let client: ApolloClient<NormalizedCacheObject>;
export const reinitializeApolloClient = () => {
  client = initApollo();

  return client;
};
