// Enums
export enum Resource {
  All = 'All',

  Analytics = 'Analytics',
  AnalyticsBrandRemittance = 'Analytics:brandRemittance',
  AnalyticsBrandSales = 'Analytics:brandSales',
  AnalyticsDailySales = 'Analytics:dailySales',
  AnalyticsGiftCard = 'Analytics:giftCard',
  AnalyticsItemSales = 'Analytics:itemSales',
  AnalyticsItemTransactions = 'Analytics:itemTransactions',
  AnalyticsItemisedTransactions = 'Analytics:itemisedTransactions',
  AnalyticsList = 'Analytics:list',
  AnalyticsModifierSales = 'Analytics:modifierSales',
  AnalyticsOperationsDetailed = 'Analytics:operationsDetailed',
  AnalyticsPartnerRemittance = 'Analytics:partnerRemittance',
  AnalyticsPlatforMix = 'Analytics:platformMix',
  AnalyticsPromo = 'Analytics:promo',
  AnalyticsTimeOfDayBreakdown = 'Analytics:timeOfDayBreakdown',
  AnalyticsTransaction = 'Analytics:transaction',
  AnalyticsPowerBi = 'Analytics:powerBi',

  Brands = 'Brands',
  BrandsCategories = 'Brands:categories',
  BrandsList = 'Brands:list',
  BrandsMenuItems = 'Brands:menuItems',
  BrandsModifiers = 'Brands:modifiers',
  BrandsMultiBrandMealPack = 'Brands:multiBrandMealPack',
  BrandsOperationHours = 'Brands:operationHours',
  BrandsProfile = 'Brands:profile',
  BrandsActiveOrders = 'Brands:activeOrders',

  ContentMarketing = 'ContentMarketing',

  Customers = 'Customers',

  DevTools = 'DevTools',
  DriverManagement = 'DriverManagement',

  FacilitySettings = 'FacilitySettings',
  FacilitySettingsBrandSorting = 'FacilitySettings:brandSorting',
  FacilitySettingsOrderMethodOverrides = 'FacilitySettings:orderMethodOverrides',
  FacilitySettingsPlatformSettings = 'FacilitySettings:platformSettings',
  FacilitySettingsProfile = 'FacilitySettings:profile',
  FacilitySettingsTaxCalculations = 'FacilitySettings:taxCalculations',
  FacilitySettingsOperationHours = 'FacilitySettings:operationHours',

  KDS = 'KDS',

  MenuPlanner = 'MenuPlanner',

  OrderMonitoring = 'OrderMonitoring',
  OrderMonitoringList = 'OrderMonitoring:list',
  OrderMonitoringOrderOptions = 'OrderMonitoring:orderOptions',
  OrderMonitoringOrderStatusFilter = 'OrderMonitoring:orderStatusFilter',

  Promos = 'Promos',

  UserManagement = 'UserManagement',

  QrCode = 'QrCode',
}

export enum Action {
  CREATE = 'Create',
  READ = 'Read',
  UPDATE = 'Update',
  DELETE = 'Delete',
  ALL = 'All',
}

export enum Permission {
  ALLOW = 'ALLOW',
  DENY = 'DENY',
}
