import React from 'react';

import { Row, Col } from 'common/components/Layout';
import { Text } from 'common/components/Text';

interface Props {
  inventoryPreSale: number;
}

export const PhysicalInventory = ({ inventoryPreSale }: Props) => {
  return (
    <Row alignItems="center">
      <Col alignItems="center">
        <Text marginRight="xs" color="steelWool">
          Pre-sale:
        </Text>
        <Text marginRight="xs">{inventoryPreSale}</Text>
      </Col>
    </Row>
  );
};
