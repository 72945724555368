import styled from 'styled-components';

import { themeColors } from 'common/theme/constants';
import {
  position,
  background,
  font,
  fontSize,
  lineHeight,
  color,
  minWidth,
  radius,
  padding,
  customScrollBar,
} from 'common/utils/styleHelpers';

export const Wrapper = styled.div<{ fluid?: boolean }>(
  ({ fluid }) => `
  ${position('relative')}
  ${fluid ? 'width: 100%;' : ''}
`,
);

export const StyledTextarea = styled.textarea(
  () => `
  ${background('beige')}
  ${font('primary')}
  ${fontSize('body')}
  ${lineHeight('normal')}
  ${color(themeColors.primaryText)}
  ${padding(20)}
  ${minWidth('100px')}
  ${radius('xSmall')}
  ${customScrollBar}
  border: none;
  resize: none;
  outline:none;
  width: 100%;
`,
);

export const Length = styled.div(
  () => `
  ${position('absolute')}
  ${color([themeColors.primaryText, 0.6])}
  ${font('primary')}
  ${fontSize('xs')}
  bottom: 12px;
  right: 12px;
}
`,
);
