import React, { ReactNode } from 'react';

import * as S from './styles';

export const NetworkInfoRow = ({
  label,
  children,
}: {
  label: string | ReactNode;
  children: ReactNode;
}) => (
  <S.Row>
    <S.RowItem>
      {typeof label === 'string' ? <strong>{label}</strong> : <>{label}</>}
    </S.RowItem>
    <span>{children}</span>
  </S.Row>
);
