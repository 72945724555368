import styled, { IconButtonSizes, IconSizes, Color } from 'styled-components';

import {
  primaryFn,
  secondaryFn,
  ghostFn,
} from 'common/components/Button/styles';
import {
  background,
  color as iconColor,
  iconSize,
  fontSize,
  radius,
  alignItems,
  justifyContent,
  display,
  textTransform,
  textAlign,
} from 'common/utils/styleHelpers';

export const MenuIcon = styled.i<{
  active: boolean;
  size: keyof IconSizes;
  color?: keyof Color;
  activeColor?: keyof Color;
}>(
  ({ active, size, color = 'white', activeColor = 'white' }) => `
  ${active ? iconColor(activeColor) : iconColor(color)}
  ${iconSize(size)}
`,
);

export interface IconButtonWrapperProps {
  active?: boolean;
  size?: keyof IconButtonSizes;
  primary?: boolean;
  secondary?: boolean;
  ghost?: boolean;
  backgroundColor?: keyof Color;
  color?: keyof Color;
  activeColor?: keyof Color;
}

export const Wrapper = styled.a<IconButtonWrapperProps>(
  ({
    theme,
    active,
    size = 'normal',
    backgroundColor = 'primaryGreen',
    primary,
    secondary,
    ghost,
  }) => `
  ${active ? background(backgroundColor) : background('grayDarkBg')}
  ${fontSize('md')}
  ${radius('half')}
  ${display('inline-flex')}
  ${alignItems('center')}
  ${justifyContent('center')}
  ${textTransform('uppercase')}
  ${textAlign('center')}

  cursor: pointer;
  width: ${theme.iconButtonSizes[size]};
  height: ${theme.iconButtonSizes[size]};
  border: 0;

  ${primary ? primaryFn('normal', backgroundColor) : ''}
  ${secondary ? secondaryFn('normal', undefined, backgroundColor) : ''}
  ${ghost ? ghostFn('normal', undefined, backgroundColor) : ''}

  &:hover{
    ${primary ? primaryFn('hover', backgroundColor) : ''}
    ${secondary ? secondaryFn('hover', undefined, backgroundColor) : ''}
    ${ghost ? ghostFn('hover', undefined, backgroundColor) : ''}
  }

  &:disabled{
    cursor: not-allowed;
    ${primary ? primaryFn('disabled', backgroundColor) : ''}
    ${secondary ? secondaryFn('disabled', undefined, backgroundColor) : ''}
    ${ghost ? ghostFn('disabled', undefined, backgroundColor) : ''}
  }

  :hover {
    ${MenuIcon} {
      opacity: 0.7;
    }
  }
  `,
);
