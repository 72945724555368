// TODO: Make values of the options from the theme visible on place of the usage.
// e.g.: ${marginL('sm')} => 'sm' is string, but to know how much is the value of sm I need to look into theme
// Or make the values consistent, small will be alway e.g.: 8px in cross of theme
import { DefaultTheme } from 'styled-components';

import themeConfig from './themeConfig';

export { GlobalFonts } from './fonts';
export { GlobalStyles } from './globalStyles';

export const theme: DefaultTheme = themeConfig;
