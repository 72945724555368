import React from 'react';

import { Color, FontWeight, FontSizes, Spacing } from 'styled-components';

import { Text } from 'common/components/Text';
import { themeColors } from 'common/theme/constants';

import { Shape, Wrapper } from './styles';

interface Props {
  text: string;
  textColor?: keyof Color;
  backgroundColor?: keyof Color;
  fontWeight?: keyof FontWeight;
  fontSize?: keyof FontSizes;
  shape?: Shape;
  marginRight?: keyof Spacing;
  marginLeft?: keyof Spacing;
  noWrap?: boolean;
}

export const Badge = ({
  text,
  backgroundColor = 'smokeWhite',
  textColor = themeColors.primaryText,
  fontWeight = 'normal',
  fontSize = 'small',
  shape,
  marginRight = 'none',
  marginLeft = 'none',
  noWrap = false,
}: Props) => (
  <Wrapper
    shape={shape}
    backgroundColor={backgroundColor}
    marginRight={marginRight}
    marginLeft={marginLeft}
  >
    <Text
      aria-label="Badge"
      color={textColor}
      fontWeight={fontWeight}
      fontSize={fontSize}
      textAlign="center"
      lineHeight="initial"
      whitespace={noWrap ? 'nowrap' : 'normal'}
    >
      {text}
    </Text>
  </Wrapper>
);
