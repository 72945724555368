import { ClientLogDocument } from 'codegen/generated/graphql';

import { reinitializeApolloClient } from './apollo';
import { getScreenId } from './screenSetup';
import { client } from '../App';

export const ENABLE_SUBSCRIPTION_LOGGING = Boolean(
  process.env.ENABLE_SUBSCRIPTION_LOGGING,
);

export const logToGCP = (message: string, meta?: Record<string, unknown>) => {
  const extendedMeta = { ...meta, screenId: getScreenId() };

  if (!client) {
    reinitializeApolloClient();
  }

  return client.mutate({
    mutation: ClientLogDocument,
    variables: {
      message,
      meta: JSON.stringify(extendedMeta),
    },
  });
};

export const logSubscriptionError =
  (meta?: Record<string, unknown>) => (error: Error) => {
    const message = 'Error while processing the update from subscription';

    return logToGCP(message, { ...meta, error });
  };
