import styled from 'styled-components';

import { Button as AppButton } from 'common/components/Button';
import { Icon, IconSize } from 'common/components/Icon';
import {
  flex,
  background,
  shadow,
  radius,
  display,
  gridColumn,
  gridGap,
  paddingX,
  borderB,
  paddingY,
  alignItems,
  marginL,
  justifyContent,
  minWidth,
  marginB,
  marginX,
  marginT,
  color,
} from 'common/utils/styleHelpers';

import { RowProps } from './types';

export const Wrapper = styled.div<RowProps>(
  ({ hasSeparateRows, simple, showHeaders }) => `
  ${flex('column')}
  ${simple ? '' : background(hasSeparateRows ? 'transparent' : 'white')}
  ${hasSeparateRows || !showHeaders ? '' : shadow('card')}
  ${radius('normal')}
  flex: 1;

 & {
  letter-spacing: 0.14px;
 }
`,
);

/**
 * Grid column Calculated from px to percentage
 */
export const TableHeader = styled.div<{
  columns: string;
  lightHeader?: boolean;
}>(
  ({ columns, lightHeader }) => `
  ${display('grid')}
  ${gridColumn(columns)}
  ${gridGap('lg')}
  ${paddingX('md')}
  ${!lightHeader && borderB(1)};
  ${background(lightHeader ? 'transparent' : 'black')}
  ${color(lightHeader ? 'black' : 'white')}
  padding-top: 20px;
  padding-bottom: 20px;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;

  i {
    ${marginL('sm')}
  }
`,
);

export const TableRow = styled.div<RowProps>(
  ({ columns, hasSeparateRows, simple, alternateRowColor = false }) => `
  ${display('grid')}
  ${gridColumn(columns)}
  ${simple ? '' : gridGap('lg')}
  ${simple ? '' : paddingX('md')}
  ${simple ? '' : paddingY('md')}
  ${simple ? '' : background(hasSeparateRows ? 'white' : 'transparent')}
  ${simple ? '' : marginB(hasSeparateRows ? 16 : 0)}
  ${simple ? '' : radius(hasSeparateRows ? 'normal' : 'none')}
  ${!simple && hasSeparateRows ? shadow('card') : ''}

  :not(:last-child){
    ${simple ? '' : borderB(1)}
  }

  &.table-row:nth-of-type(even){
    ${alternateRowColor ? 'background: #f9f9f9;' : ''}
  }

`,
);

export type CellAlignProps = 'right' | 'center';
export const Cell = styled.div<{ align?: CellAlignProps; sortable?: boolean }>(
  ({ align, sortable }) => `
  ${flex('row')}
  ${align === 'right' ? justifyContent('flex-end') : ''}
  ${align === 'center' ? justifyContent('center') : ''}
  ${alignItems('center')}
  ${minWidth('0')}
  ${sortable ? 'cursor: pointer;' : ''}

  p {
    ${display('inline-grid')}
    ${alignItems('center')}
    line-height: 1;
  }
`,
);

export const Pagination = styled.div(
  () => `
  ${display('flex')}
  ${alignItems('center')}
  ${justifyContent('flex-end')}
  ${marginT('sm')}
  width: 100%;
`,
);

export const Button = styled.button(
  () => `
  ${background('black')}
  ${display('flex')}
  ${justifyContent('center')}
  ${alignItems('center')}
  ${radius('small')}
  ${marginX(2)}
  cursor: pointer;
  border: 0;
  outline: none;
  height: 24px;

  &:disabled {
    ${background('grayDark')}
    cursor: not-allowed;
  }
`,
);

export const ButtonIcon = styled(Icon).attrs({
  size: IconSize.SMALL,
  iconColor: 'white',
})``;

export const SortIcon = styled.img(
  () => `
  ${marginL('xs')}
`,
);

/**
 * Table cell was having hard time to
 * make truncate text it was growing the cell
 * so minWidth(0) put there to eliminate that
 * problem
 * Url of solution : https://dev.to/timhecker/grid-cell-issue-with-white-space-nowrap--text-overflow-ellipsis-52g6
 */

export const DropdownItem = styled.div<{ selected: boolean }>(
  ({ selected }) => `
  max-width: 250px;
  background: ${selected ? '#C8F8E0' : 'transparent'};
  padding-left: 16px;
`,
);

export const DropdownItemList = styled.div`
  margin: 0 -16px;
  max-height: 260px;
  overflow-y: auto;
  overflow-x: hidden;
`;

export const SlicerButton = styled(AppButton)`
  background: #ffffff;
  margin-left: 12px;
  padding-left: 18px;
  padding-right: 18px;
  border: 1px solid #e0e0e0;
`;
