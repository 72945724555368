import styled from 'styled-components';

import { Row } from 'common/components/Layout';
import { border as borderSize } from 'common/utils/styleHelpers';

type Props = {
  size?: 'sm' | 'normal' | 'custom';
  border?: boolean;
};

const sizes = {
  custom: {
    paddingY: 15,
    paddingX: 40,
  },
  sm: {
    paddingY: 10,
    paddingX: 20,
  },
  normal: {
    paddingY: 30,
    paddingX: 40,
  },
};

export const ListItem = styled(Row).attrs(({ size = 'normal' }: Props) => ({
  background: 'white',
  paddingY: size ? sizes[size].paddingY : sizes.normal.paddingY,
  paddingX: size ? sizes[size].paddingX : sizes.normal.paddingX,
  borderRadius: 'normal',
  direction: 'row',
  marginB: 'sm',
  justifyContent: 'space-between',
}))(
  ({ border }: Props) => `
  ${border && borderSize(1)}
`,
);
