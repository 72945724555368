import React, { useState, useMemo } from 'react';
import { useHistory } from 'react-router';

import { useAuthContext } from 'authContext';
import { MenuItemType, InventoryStatus } from 'codegen/generated/graphql';
import { Button } from 'common/components/Button';
import { Input } from 'common/components/Input';
import { Row, Col } from 'common/components/Layout';
import { themeColors } from 'common/theme/constants';
import { ButtonTabs } from 'manager/components/ButtonTabs';
import { KitchenListItem } from 'manager/containers/RestaurantAdministration/Restaurants/MenuList/types';
import { Action, Resource } from 'router/AccessControlConfig';
import { Routes } from 'router/routes';
import { isUserRestaurantOwner, isUserCustomerSupport } from 'utils';
import { LogEvent } from 'utils/analytics';
import { getRoute } from 'utils/getRoute';

import { Categories } from './components/Categories';
import { SortableMenuList } from './components/SortableMenuList';
import { Visibility } from './components/Visibility';
import { Props } from './types';
import { AccessControl } from '../../../../../common/components/AccessControl/AccessControl';

const options = [
  {
    label: 'All',
    value: 'All',
  },
  {
    label: 'Available',
    value: InventoryStatus.InStock,
  },
  {
    label: 'Sold Out',
    value: InventoryStatus.SoldOut,
  },
];

type Filter = 'All' | InventoryStatus;

export const MenuList = ({
  kitchenId,
  items,
  showCategory,
  readOnly,
  onChange,
}: Props) => {
  const { push } = useHistory();
  const { user } = useAuthContext();
  const [filter, setFilter] = useState<Filter>('All');
  const [categories, setCategories] = useState<string[]>([]);
  const [availableOn, setAvailableOn] = useState<string[]>([]);
  const [searchTerm, setSearchTerm] = useState('');

  const isRestaurantOwner = isUserRestaurantOwner(user?.roles);
  const isCustomerSupport = isUserCustomerSupport(user?.roles);

  const displayedItems = useMemo<KitchenListItem[]>(() => {
    let filteredItems: KitchenListItem[] = items;

    if (categories.length) {
      filteredItems = filteredItems.filter((item) =>
        item.menuCategories.some((menuCategory) =>
          categories.some((category) => category === menuCategory.id),
        ),
      );
    }

    if (availableOn.length) {
      filteredItems = filteredItems.filter((item) =>
        item.availability.some((avail) => availableOn.includes(avail)),
      );
    }

    return filteredItems;
  }, [categories, availableOn, items]);

  const searchedItems = searchTerm
    ? displayedItems.filter((item) =>
        item.name.toLowerCase().includes(searchTerm.toLowerCase()),
      )
    : displayedItems;

  const filteredItems =
    filter !== 'All'
      ? searchedItems.filter((item) => item.inventoryStatus === filter)
      : searchedItems;

  return (
    <AccessControl resource={Resource.BrandsMenuItems} action={Action.READ}>
      <Row marginB="lg" justifyContent="space-between">
        <Col marginB={16}>
          <ButtonTabs
            options={options}
            value={filter}
            onChange={async (value) => {
              setFilter(value as Filter);
            }}
            checkPartially
          />
        </Col>
        <Col>
          <Row>
            {!readOnly && !isCustomerSupport && (
              <>
                <Col marginR={10}>
                  <Button
                    iconName=""
                    data-cy="admin-restaurant-addfood-button"
                    secondary
                    text={
                      <>
                        <img src="/svg/ic-burger.svg" alt="Add food" /> Add food
                      </>
                    }
                    onClick={async () => {
                      if (kitchenId)
                        push(
                          getRoute(
                            Routes.MENU_ADD.path,
                            kitchenId,
                            MenuItemType.Food,
                          ),
                        );
                      await LogEvent('MENU_MANAGEMENT', {
                        message: `clicked "add new menu item"`,
                        id: kitchenId,
                      });
                    }}
                  />
                </Col>
                <Col marginR={10}>
                  <Button
                    iconName=""
                    secondary
                    text={
                      <>
                        <img src="/svg/ic-drink.svg" alt="Add drink" /> Add
                        drink
                      </>
                    }
                    onClick={async () => {
                      if (kitchenId)
                        push(
                          getRoute(
                            Routes.MENU_ADD.path,
                            kitchenId,
                            MenuItemType.Drink,
                          ),
                        );
                      await LogEvent('MENU_MANAGEMENT', {
                        message: `clicked "add new menu item"`,
                        id: kitchenId,
                      });
                    }}
                  />
                </Col>
                <Col marginR={20}>
                  <Button
                    iconName=""
                    secondary
                    text={
                      <>
                        <img src="/svg/pickup_dark.svg" alt="Add Meal Pack" />
                        Add meal pack
                      </>
                    }
                    onClick={async () => {
                      if (kitchenId)
                        push(getRoute(Routes.ADD_MEAL_PACK.path, kitchenId));
                      await LogEvent('MENU_MANAGEMENT', {
                        message: `clicked "add new menu item"`,
                        id: kitchenId,
                      });
                    }}
                  />
                </Col>
              </>
            )}
            <Col width="300px" marginR="sm">
              <Visibility onAvailSelect={setAvailableOn} />
            </Col>

            <Col width="300px" marginR="sm">
              <Categories
                items={items}
                onCategorySelect={setCategories}
                mode="list"
              />
            </Col>
            <Col>
              <Input
                fluid
                type="search"
                placeholder="Search"
                iconSrc="/svg/search.svg"
                onChange={(e) => setSearchTerm(e.target.value)}
                value={searchTerm}
                background={themeColors.secondaryBackground}
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <SortableMenuList
        readOnly={isCustomerSupport}
        isRestaurantOwner={isRestaurantOwner}
        isCustomerSupport={isCustomerSupport}
        disabled={isCustomerSupport}
        showCategory={showCategory}
        items={filteredItems}
        onChange={onChange}
        kitchenId={kitchenId}
        sortable={!isCustomerSupport}
      />
    </AccessControl>
  );
};
