import styled from 'styled-components';

import { themeColors } from 'common/theme/constants';
import {
  display,
  justifyContent,
  alignItems,
  background,
  radius,
  border,
  borderColor,
} from 'common/utils/styleHelpers';

export const BackButton = styled.div(
  () => `
  ${display('flex')}
  ${justifyContent('center')}
  ${alignItems('center')}
  ${background(themeColors.secondaryBackground)}
  ${radius('half')}
  ${border(1)}
  ${borderColor(themeColors.primaryText)}
  cursor: pointer;
  width: 32px;
  height: 32px;
`,
);
