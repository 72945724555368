import styled from 'styled-components';

import {
  alignItems,
  alignSelf,
  background,
  backgroundImage,
  color,
  display,
  flex,
  flexDirection,
  fontSize,
  justifyContent,
  marginR,
  marginT,
  paddingX,
  paddingY,
  radius,
  textAlign,
  textTransform,
} from 'common/utils/styleHelpers';

export const ImageCover = styled.div<{ image: string }>(
  ({ image }) => `
  ${display('flex')}
  ${flexDirection('column')}
  ${justifyContent('center')}
  ${backgroundImage(image, 'cover', 'no-repeat', 'center')}
  width: 100%;
  height: 320px;
  position: relative;

  &:after {
    content: '';
    position: absolute;
    width: 100%; 
    height:100%;
    top:0; 
    left:0;
    background:rgba(0,0,0,0.5);
}

  span {
    font-weight: bold;
    margin-right: 10px;
  }
`,
);

export const KitchenTag = styled.div(
  () => `
  ${background('yellow')}
  ${radius('medium')}
  ${paddingY('xs')}
  ${paddingX('md')}
  ${marginR('xs')}
  ${alignItems('center')}
  ${textAlign('center')}    
  width: max-content;
  z-index: 999;

  p {
      line-height: inherit;
      ${textTransform('uppercase')}
  }
`,
);

export const InfoBox = styled.div(
  () => `
  ${flex('row')}
  ${marginT('sm')}
  z-index: 999;

  > * {
    ${flex('row')}
    ${marginR('xl')}
    
    ::before{
      ${flex('row')}
      ${fontSize('xxxl')}
      ${display('inline-flex')}
      ${alignSelf('center')};
      ${marginR('md')}
      content: '•';
      line-height: 24px;
      writing-mode: vertical-lr;
      border-radius: 100%;
    };

    :nth-child(1):before{
      ${color('blue')};
    }
    :nth-child(2):before{
      ${color('greenBright')};
    }
    :nth-child(3):before{
      ${color('yellow')};
    }
  }
`,
);

export const HeaderBox = styled.div(
  () => `
  ${flex('row')}
  ${alignItems('center')} 
  ${justifyContent('space-between')}
  ${paddingY('md')}
  z-index: 999;
`,
);
