import cuid from 'cuid';
import moment from 'moment';

import { dateInFacilityTz } from 'common/utils/time';
import { getStorageFacilityId } from 'utils';

const LS_SCREEN_ID = 'screenId';
const LS_SUBSCRIPTION_STATUS = 'subscriptionStatus';
const LS_SUBSCRIPTION_LAST_CONNECTION = 'subscriptionLastConnection';

export const initScreenSetup = () => {
  const currentScreenId = localStorage.getItem(LS_SCREEN_ID);
  if (!currentScreenId) {
    localStorage.setItem(LS_SCREEN_ID, cuid().substr(0, 6));
  }
};

export const getScreenId = () => {
  return localStorage.getItem(LS_SCREEN_ID);
};

export const getSubscriptionConnectionInfo = () => {
  return {
    status:
      sessionStorage.getItem(LS_SUBSCRIPTION_STATUS) ===
      ConnectionStatus.Connected
        ? ConnectionStatus.Connected
        : ConnectionStatus.Disconnected,
    lastConnection: sessionStorage.getItem(LS_SUBSCRIPTION_LAST_CONNECTION),
    facilityId: getStorageFacilityId(),
  };
};

export enum ConnectionStatus {
  Connected = 'connected',
  Disconnected = 'disconnected',
}

export const setSubscriptionConnected = (isConnected: boolean) => {
  sessionStorage.setItem(
    LS_SUBSCRIPTION_STATUS,
    isConnected ? ConnectionStatus.Connected : ConnectionStatus.Disconnected,
  );
  if (isConnected) {
    sessionStorage.setItem(
      LS_SUBSCRIPTION_LAST_CONNECTION,
      moment(dateInFacilityTz(new Date())).format('HH:mm'),
    );
  }
};

export enum KDSSettingsKey {
  AutoPrint = 'autoPrint',
  OrderSound = 'orderSound',
  BrandName = 'brandName',
}
export const KDS_SETTINGS_KEY = 'kdsSettings';

export const kdsSettings = localStorage.getItem(KDS_SETTINGS_KEY);
