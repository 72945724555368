import React from 'react';

import { Icon } from 'common/components/Icon';
import { Row } from 'common/components/Layout';
import { Modal } from 'common/components/Modal';
import { Text } from 'common/components/Text';
import { themeColors } from 'common/theme/constants';

import { KdsButton } from '../ButtonWrapper/styles';

interface Props {
  visible: boolean;
  onClose: () => void;
  message?: string;
}

export const ErrorModal: React.FC<Props> = ({ visible, onClose, message }) => (
  <Modal visible={visible} onCloseClick={onClose} size={560}>
    <Row direction="row" paddingX={40} paddingY={70} justifyContent="center">
      <Icon
        iconColor={themeColors.primaryRed}
        size={60}
        url="/svg/warning.svg"
      />
      <Text
        fontSize="h2"
        fontWeight="bold"
        lineHeight="xxLarge"
        textAlign="center"
        whitespace="normal"
        spaceAfter={30}
      >
        {message || 'The action can’t be completed due to the technical error.'}
      </Text>
      <KdsButton
        primary
        minWidth="320px"
        text="Try again"
        onClick={onClose}
        size="big"
      />
    </Row>
  </Modal>
);
