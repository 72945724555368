export type SortProps = {
  oldIndex: number;
  newIndex: number;
  items: any[];
};

export const menuItemSorting = <T extends SortProps>({
  items,
  oldIndex,
  newIndex,
}: T) => {
  const item = items[oldIndex];
  const newSort = [...items];

  if (oldIndex > newIndex) {
    newSort.splice(oldIndex, 1);
    newSort.splice(newIndex, 0, item);
  } else {
    newSort.splice(newIndex + 1, 0, item);
    newSort.splice(oldIndex, 1);
  }

  return newSort;
};

export const moveArrayElement = <T>(
  array: T[],
  from: number,
  to: number,
): T[] => {
  const newArray = [...array];
  const element = newArray[from];
  newArray.splice(from, 1);
  newArray.splice(to, 0, element);

  return newArray;
};
