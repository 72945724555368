import { Button } from 'antd';
import styled from 'styled-components';

import { Col } from 'common/components/Layout';
import { Text } from 'common/components/Text';
import { background } from 'common/utils/styleHelpers';

export const Dot = styled.span(
  ({ online }: { online: boolean }) => `
    display: inline-block;
    ${background(online ? 'primaryGreen' : 'primaryRed')}
    width: 10px;
    height: 10px;
    border-radius: 100%;
    margin-right: 10px;
`,
);

export const NetworkInfoControlWrapper = styled(Col)`
  background: ${({ theme }) => theme.color.charcoalGrey};
`;

export const OptionsWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start !important;
  gap: 32px;
`;
export const Row = styled.div`
  display: flex;
  justify-content: space-between !important;
  align-items: center;
  width: 100%;
`;
export const SectionContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 16px;
  & > div {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
`;

export const RowItem = styled.div`
  display: flex;
  flex-direction: column;
  font-weight: bolder !important;
`;

export const Wrapper = styled(Row)`
  margin-top: ${({ theme }) => theme.spacing.md};
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Column = styled(Col)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

export const SubText = styled(Text)`
  text-decoration: underline;
  font-weight: ${({ theme }) => theme.fontWeight.thin};
  color: ${({ theme }) => theme.color.black};
`;

export const HiddenButton = styled(Button)`
  padding: 0;
  text-align: left;
  margin: 0;
`;
