import React, { useContext, useState } from 'react';

import { useAuthContext } from 'authContext';
import { UserRole } from 'codegen/generated/graphql';
import { StandardizedTableProps, TableData } from 'common/components/Table';
import { localizedToday, localizedWeekAgo } from 'common/utils/time';
import { TimeFilter } from 'manager/utils/types';
import { getMostPrivilegedRole } from 'utils';

// TODO: evaluate rewriting with Apollo3 Variables: https://www.apollographql.com/docs/react/local-state/reactive-variables/
const TIME_FILTER_INITIAL = {
  startDate: localizedWeekAgo,
  endDate: localizedToday,
};

type Values = TableData;

export type TExportData = Pick<
  StandardizedTableProps<Values>,
  'data' | 'exportData' | 'columns'
> & {
  fileName: string;
  hiddenColumns: number[];
  exportLoading?: boolean;
};

export type TReportContext = {
  role?: UserRole;
  timeFilter: TimeFilter;
  facilityIds: string[];
  kitchenIds: string[];
  reportsObject?: TExportData;
  hiddenIds: string[];
  setTimefilter: (timeFilter: TimeFilter) => void;
  setReportsObject: (obj: TExportData) => void;
  setFacilityIds: (ids: string[]) => void;
  setKitchenIds: (ids: string[]) => void;
  setHiddenIds: (ids: string[]) => void;
};

export const ReportContext = React.createContext<TReportContext>({
  role: undefined,
  timeFilter: TIME_FILTER_INITIAL,
  facilityIds: [],
  kitchenIds: [],
  hiddenIds: [],
  reportsObject: undefined,
  setReportsObject: () => {},
  setTimefilter: () => {},
  setFacilityIds: () => {},
  setKitchenIds: () => {},
  setHiddenIds: () => {},
});

type ProviderProps = {
  children: React.ReactNode;
};

export const ReportContextProvider = ({ children }: ProviderProps) => {
  const { user } = useAuthContext();
  const [timeFilter, setTimefilter] = useState<TimeFilter>(TIME_FILTER_INITIAL);
  const [kitchenIds, setKitchenIds] = useState<string[]>([]);
  const [facilityIds, setFacilityIds] = useState<string[]>([]);
  const [hiddenIds, setHiddenIds] = useState<string[]>([]);
  const [reportsObject, setReportsObject] = useState<TExportData>();

  const role = getMostPrivilegedRole(user?.roles || []);

  return (
    <ReportContext.Provider
      value={{
        role,
        timeFilter,
        facilityIds,
        kitchenIds,
        reportsObject,
        hiddenIds,
        setHiddenIds,
        setReportsObject,
        setTimefilter,
        setFacilityIds,
        setKitchenIds,
      }}
    >
      {children}
    </ReportContext.Provider>
  );
};

export const useReportContext = () => useContext(ReportContext);
