import { Permission, Action, Resource } from './enums';

export type AccessControlRules = Partial<
  Record<Resource, Partial<Record<Action, Permission>>>
>;

type ResourceActionPair = {
  resource: Resource;
  action: Action;
};

export const isGranted = (
  { resource, action }: ResourceActionPair,
  rules: AccessControlRules,
): boolean => {
  if (!rules) {
    return false;
  }

  const isAllowedAllResources =
    rules[Resource.All]?.[Action.ALL] === Permission.ALLOW;

  // Return true if 'ALLOW' permission is granted for all resources and actions
  if (isAllowedAllResources) {
    return true;
  }

  // Check if the resource exists in the rules, and if not, return false
  const resourceRules = rules[resource];
  if (!resourceRules) {
    return false;
  }

  // Check if the action exists in the resource rules, and if not, return false
  const actionPermission = resourceRules[action];
  if (!actionPermission) {
    return false;
  }

  // Return true if the action is allowed, otherwise return false
  return actionPermission === Permission.ALLOW;
};
