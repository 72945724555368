import styled from 'styled-components';

import { Icon, IconSize } from 'common/components/Icon';
import { themeColors } from 'common/theme/constants';
import {
  display,
  justifyContent,
  alignItems,
  radius,
  position,
  paddingT,
  paddingB,
  background,
  minWidth,
  padding,
  margin,
  paddingX,
  paddingY,
  flex,
  gridGap,
  marginR,
  marginX,
  marginT,
} from 'common/utils/styleHelpers';

export const IconButtonWrapper = styled.div<{
  inline?: boolean;
  locationToggle?: boolean;
  reverseList?: boolean;
}>(
  ({ inline, locationToggle, reverseList }) => `
  ${position('relative')}
  ${display('inline-flex')}
  ${alignItems('center')}
  ${justifyContent('flex-start')}
  ${radius('half')}
  ${
    inline || locationToggle || reverseList
      ? ''
      : background(themeColors.primaryText)
  }
  user-select: none;
  width: ${locationToggle || reverseList ? 'auto' : '32px'};
  height: ${locationToggle || reverseList ? 'auto' : '32px'};
  cursor: pointer;
`,
);

export const Menu = styled.div<{
  isOpen: boolean;
  inline?: boolean;
  leftAlign?: boolean;
  reverseList?: boolean;
}>(
  ({ isOpen, inline, leftAlign, reverseList }) => `
  ${display(isOpen ? 'block' : 'none')}
  ${position('absolute')}
  ${paddingT('xs')}
  ${paddingB('sm')}
  ${background(themeColors.secondaryText)}
  ${radius('xSmall')}
  ${minWidth('100px')}
  width: max-content;
  top: ${inline ? '30px' : reverseList ? '' : '40px'};
  bottom: ${reverseList ? '30px' : ''};
  ${leftAlign ? 'left: 0' : 'right: 10px'};
  box-shadow: 0px 0px 10px rgba(84, 84, 84, 0.09);
  z-index: 999;
  `,
);

export const List = styled.ul(
  () => `
  ${flex('column')}
  ${padding(0)}
  ${margin(0)}
`,
);

export const ListItem = styled.li(
  () => `
  ${paddingY(4)}
  ${paddingX('sm')}
  ${display('inline-flex')}
  ${gridGap('xs')}
  ${alignItems('center')}

  i {
    ${marginR(8)}
  }
  p {
    ${marginT(2)}
  }

  :hover {
    ${background([themeColors.primaryText, 0.1])}
  }
`,
);

export const IconWrapper = styled.div<{ active?: boolean }>(
  () => `
  ${display('inline-flex')}
  ${alignItems('center')}
  ${justifyContent('center')}
`,
);

export const Button = styled.button(
  () => `
  ${background([themeColors.primaryBackgound, 0.1])}
  ${display('flex')}
  ${justifyContent('center')}
  ${alignItems('center')}
  ${radius('small')}
  ${marginX(2)}
  cursor: pointer;
  border: 0;
  outline: none;
  height: 34px;
  width: 70px;
`,
);

export const ButtonIcon = styled(Icon).attrs({
  size: IconSize.LARGE,
  iconColor: themeColors.primaryText,
})``;

export const FacilitySwitcherWrapper = styled.div`
  display: grid;
  grid-template-columns: 25px 150px;
  grid-column-gap: 15px;
  justify-content: center;
`;

export const LocationIconContainer = styled.div<{ isSelected: boolean }>(
  ({ isSelected }) => `
  display: grid;
  width: 30px;
  height: 30px;
  border-radius: 15px;
  background-color: ${isSelected ? '#779557' : 'grey'};
  opacity: ${isSelected ? 1 : 0.7};
  padding-left: 5px;
  padding-top: 5px;
`,
);

export const FacilitySwitcherText = styled.div`
  padding-top: 5px;
`;
