import React from 'react';

import { useKdsKitchenTicketsQuery } from 'codegen/generated/graphql';
import { Grid } from 'common/components/Layout';
import { RECENTLY_DONE_LIMIT } from 'utils';

import { Item } from './Item';

interface Props {
  kitchenId: string;
}

export const ActiveOrders = ({ kitchenId }: Props) => {
  const { data } = useKdsKitchenTicketsQuery({
    variables: { kitchenId, recentlyDoneLimit: RECENTLY_DONE_LIMIT },
    pollInterval: 60 * 1000,
    fetchPolicy: 'no-cache',
  });

  return (
    <Grid gridGap="md" gridColumns="repeat(4,1fr)">
      {data?.kdsKitchenTickets.map((ticket) => (
        <Item key={ticket.id} ticket={ticket} />
      ))}
    </Grid>
  );
};
