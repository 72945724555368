import styled from 'styled-components';

import { themeColors } from 'common/theme/constants';
import {
  paddingY,
  background,
  borderColor,
  shadow,
  display,
  flexDirection,
  alignItems,
  marginB,
  radius,
  justifyContent,
} from 'common/utils/styleHelpers';

export const Wrapper = styled.div(
  () => `
  ${display('inline-flex')}
  ${paddingY(20)}
  ${background(themeColors.secondaryBackground)}
  ${shadow('card')}
  ${flexDirection('column')}
  ${alignItems('center')}
  min-height: calc(100vh - 96px);
  width: 100px;
`,
);

export const ItemsWrapper = styled.div(
  () => `
  ${display('inline-flex')}
  ${flexDirection('column')}
  overflow-y: auto;

   > a {
    ${marginB(20)}
  }
`,
);

export const TextWrapper = styled.div(
  () => `
  ${flexDirection('column')}
  ${alignItems('center')}
  
  > p {
    max-width: 90px;
    text-align: center;
  }
`,
);

export const ToggleWrapper = styled.div(
  () => `
  ${display('flex')}
  ${flexDirection('column')}
  ${alignItems('center')}
  padding-bottom: 20px;
  margin-bottom: 20px;
  position: relative;
  width: 100%;

  &::after {
    content: '';
    position: absolute;
    border-width: 1px;
    border-style: solid;
    ${borderColor(themeColors.primaryText)};
    opacity: 0.1;
    bottom: 0;
    width: 70px;
    left: 13px;
  }

`,
);

export const IconWrapper = styled.div<{ active?: boolean }>(
  ({ active }) => `
  ${display('inline-flex')}
  ${alignItems('center')}
  ${justifyContent('center')}
  ${radius('half')}
  ${background(
    active ? themeColors.primaryGreen : [themeColors.primaryText, 0.1],
  )}
  width: 48px;
  height: 48px;
`,
);

export const SidebarBottomWrapper = styled.div`
  margin-top: auto;
`;
