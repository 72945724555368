import styled from 'styled-components';

import { Row, Col } from 'common/components/Layout';
import {
  background,
  radius,
  flex,
  display,
  justifyContent,
  alignItems,
  paddingX,
  position,
  zIndex,
} from 'common/utils/styleHelpers';

export const NAVBAR_HEIGHT = '96px';

export const Wrapper = styled(Row)(
  () => `
  ${flex('row')}
  ${background('black')}
  ${position('sticky')}
  ${zIndex('navbar')}
  ${paddingX(40)}
  min-height: ${NAVBAR_HEIGHT};
  align-items: center;
  top: 0;
`,
);

export const Logo = styled.div<{ logoUrl: string }>(
  ({ logoUrl }) => `
  background: url(${logoUrl}) no-repeat;
  width: 108px;
  height: 48px;
  background-size: contain;
`,
);

export const ButtonWrapper = styled(Col).attrs({
  background: ['white', 0.1],
  padding: 6,
  marginR: 20,
  borderRadius: 'xl',
  width: 'max-content',
})<{ noCursor?: boolean }>(
  ({ noCursor }) => `cursor: ${noCursor ? 'default' : 'pointer'};`,
);

export const IconWrapper = styled.div<{ active?: boolean }>(
  ({ active }) => `
  ${display('inline-flex')}
  ${alignItems('center')}
  ${justifyContent('center')}
  ${radius('half')}
  ${background(active ? 'primaryGreen' : ['white', 0.1])}
  margin-right: 20px;
  width: 48px;
  height: 48px;
`,
);
