import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { ToastProvider } from 'react-toast-notifications';

import { ApolloProvider } from '@apollo/client';
import { Router } from 'router';
import { ThemeProvider } from 'styled-components';

import { AccessControlProvider } from './contexts/AccessControlContext/accessControlContext';
import { AuthProvider } from 'authContext';
import { Tenant } from 'codegen/generated/graphql';
import { withErrorBoundary } from 'common/components/ErrorBoundary';
import { GlobalFonts, GlobalStyles, theme } from 'common/theme';
import { KDSSettingsProvider } from 'contexts/kdsSettingsContext';
import { SubscriptionsContextProvider } from 'contexts/subscriptionsContext';
import { reinitializeApolloClient } from 'utils/apollo';

import { TenantGuard } from './TenantGuard';
import { initFirebaseAnalytics, setTenantToAuth } from './utils/firebase';
import { initScreenSetup } from './utils/screenSetup';
import { initSentry } from './utils/sentry';

export { client } from 'utils/apollo';

// Importing ant design styles with reset less
import 'common/theme/reset.less';

const initApp = (tenant: Tenant) => {
  initSentry();
  initFirebaseAnalytics();

  initScreenSetup();
  setTenantToAuth(tenant);

  return reinitializeApolloClient();
};

const AppComponent = () => (
  <TenantGuard>
    {({ tenant }) => {
      const client = initApp(tenant);

      return (
        <ApolloProvider client={client}>
          <ThemeProvider theme={theme}>
            <BrowserRouter>
              <AuthProvider>
                <AccessControlProvider>
                  <ToastProvider>
                    <SubscriptionsContextProvider>
                      <KDSSettingsProvider>
                        <Router />
                        <GlobalFonts />
                        <GlobalStyles />
                      </KDSSettingsProvider>
                    </SubscriptionsContextProvider>
                  </ToastProvider>
                </AccessControlProvider>
              </AuthProvider>
            </BrowserRouter>
          </ThemeProvider>
        </ApolloProvider>
      );
    }}
  </TenantGuard>
);

export const App = withErrorBoundary(AppComponent);
