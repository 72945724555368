import React from 'react';

import { StyledTextarea, Wrapper, Length } from './styles';

type HTMLTextareaProps = React.TextareaHTMLAttributes<HTMLTextAreaElement>;

interface TextAreaProps extends HTMLTextareaProps {
  fluid?: boolean;
}

export const Textarea = (props: TextAreaProps) => {
  const { value, fluid, maxLength } = props;
  const length = value?.toString().length;

  return (
    <Wrapper fluid={fluid}>
      {maxLength && (
        <Length>
          {length}/{maxLength}
        </Length>
      )}
      <StyledTextarea {...props} />
    </Wrapper>
  );
};
