import styled from 'styled-components';

import {
  position,
  border,
  radius,
  background,
  display,
  justifyContent,
  alignItems,
  paddingX,
  paddingR,
  borderRadiusBL,
  borderRadiusBR,
  flex,
  customScrollBar,
  zIndex,
} from 'common/utils/styleHelpers';

export const Wrapper = styled.div<{
  isOpen: boolean;
  hasBackground?: boolean;
  arrowPlacement?: number;
}>(
  ({ isOpen, hasBackground, arrowPlacement }) => `
  ${position('relative')}
  ${hasBackground ? '' : border(3)}
  ${radius('large')}
  ${background(hasBackground ? 'beige' : 'white')}
  ${arrowPlacement ? paddingR(arrowPlacement) : ''}
  justifyContent: flex-start;
  cursor: pointer; 
  height: 40px;
  width: 100%;

  &, & * {
    user-select: none;
  }
  ${isOpen ? borderRadiusBL('none') : ''}
  ${isOpen ? borderRadiusBR('none') : ''}

  ${Options} {
    ${hasBackground ? '' : border(3)}
  }
`,
);

export const Selected = styled.div(
  () => `
  ${display('flex')}
  ${justifyContent('space-between')}
  ${alignItems('center')}
  width: 100%;
  height: 100%;
  padding-left: 16px;

  > div {
    display: flex;
  }
`,
);

export interface ElementPos {
  top: number;
  left: number;
  width: number;
  isOpen: boolean;
  dropdownHeight?: string;
  offSet?: number;
}
export const Options = styled.div<ElementPos>(
  ({ width, isOpen, offSet = 0 }) => `
  ${position('absolute')}
  ${background('white')}
  ${zIndex('common')}
  ${customScrollBar}
  ${isOpen ? display('block') : display('none')}
  width: ${width}px;
  top: 38px;
  left: ${offSet}px;
  max-height: 180px;
  overflow: auto;
  `,
);

const setBackground = (isSelected: boolean, hasBackground?: boolean) => {
  // workaround for no-nested-ternary rule
  if (hasBackground && isSelected) return 'white';
  if (hasBackground) return 'beige';
  if (isSelected) return 'gray';

  return 'white';
};

export const Option = styled.div<{
  isSelected: boolean;
  hasBackground?: boolean;
}>(
  ({ isSelected, hasBackground }) => `
  ${flex('row')}
  ${alignItems('center')}
  ${paddingX('md')}
  ${background(setBackground(isSelected, hasBackground))}
  box-sizing: border-box;
  height: 40px;
  width: 100%;
  cursor: pointer;
  &:hover {
    ${background(hasBackground ? 'white' : 'gray')}
  }
`,
);
