import fetch from 'cross-fetch';

export interface IUpload {
  type: 'kitchen' | 'menuItem' | 'facility' | 'kiosk' | 'heroImage';
  file: File;
}

export enum UploadType {
  Kitchen = 'kitchen',
  Facility = 'facility',
  Kiosk = 'kiosk',
  HeroImage = 'heroImage',
}

export const FILE_TYPE_ERROR =
  'Unsupported file type! Please try again within the specified requirements.';

export const FILE_SIZE_ERROR =
  'The file you’re trying to upload is too big! Please try again within the specified requirements..';

const uploadUrl = (process.env.REACT_APP_API_URL as string).replace(
  '/graphql',
  '/upload',
);

export const upload = async ({ type, file }: IUpload) => {
  const uploadPath = `${uploadUrl}/${type}`;

  const formData = new FormData();

  formData.append('file', file);

  const response: { uploadUrl: string } = await fetch(uploadPath, {
    method: 'POST',
    body: formData as any,
  }).then((res) => res.json());

  return response.uploadUrl;
};
