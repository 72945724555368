import React from 'react';
import { Route, RouteProps } from 'react-router-dom';

import { useAuthContext } from 'authContext';
import { AuthenticatedUserRedirect } from 'router/AuthenticatedUserRedirect';

export const PublicRoute = (props: RouteProps) => {
  const { user } = useAuthContext();

  if (user) {
    return <AuthenticatedUserRedirect user={user} />;
  }

  return <Route {...props} />;
};
