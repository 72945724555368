import React, { useMemo } from 'react';
import { ColumnInstance } from 'react-table';

import { SortOrderArg } from 'codegen/generated/graphql';
import { Text } from 'common/components/Text';

import { TableProps } from '../types';

import { TableHeader as TableHeaderStyled, Cell, SortIcon } from '../styles';

const SortIconSrcMap: Record<
  SortOrderArg | 'default',
  { light: string; dark: string }
> = {
  [SortOrderArg.Asc]: {
    dark: `/images/table_${SortOrderArg.Asc}_dark.png`,
    light: `/images/table-sort-${SortOrderArg.Asc}.png`,
  },
  [SortOrderArg.Desc]: {
    dark: `/images/table_${SortOrderArg.Desc}_dark.png`,
    light: `/images/table-sort-${SortOrderArg.Desc}.png`,
  },
  default: {
    dark: '/images/table_default_dark.png',
    light: '/images/table-sort-default.png',
  },
};

export const TableHeader = ({
  columnsWidth,
  lightHeader,
  headers,
}: TableProps & { headers: ColumnInstance[] }) => {
  const visibleHeaders = useMemo(() => {
    return headers.filter((c) => c.isVisible);
  }, [headers]);

  return (
    <TableHeaderStyled columns={columnsWidth} lightHeader={lightHeader}>
      {visibleHeaders.map((column, index) => (
        <Cell key={index}>
          {column.render('Header')}
          {column.canGroupBy ? (
            // If the column can be grouped, let's add a toggle
            <Text marginLeft={5} {...column.getGroupByToggleProps()}>
              {column.isGrouped ? '📌 ' : '📎 '}
            </Text>
          ) : null}
          {column.canSort && (
            <SortIcon
              {...column.getHeaderProps(column.getSortByToggleProps())}
              src={
                column.isSorted
                  ? SortIconSrcMap[
                      column.isSortedDesc ? SortOrderArg.Desc : SortOrderArg.Asc
                    ][lightHeader ? 'dark' : 'light']
                  : SortIconSrcMap['default'][lightHeader ? 'dark' : 'light']
              }
            />
          )}
        </Cell>
      ))}
    </TableHeaderStyled>
  );
};
