import styled from 'styled-components';

import { marginB } from 'common/utils/styleHelpers';

export const SuccessIcon = styled.div(
  () => `
  background: url('/images/success-icon-modal.svg');
  width: 48px;
  height: 48px;
  ${marginB(24)}
`,
);

export const ErrorIcon = styled.div(
  () => `
  background: url('/svg/ic_error.svg');
  width: 48px;
  height: 48px;
  ${marginB(24)}
`,
);

export const WarningIcon = styled.div(
  () => `
  background: url('/svg/warning_orange.svg');
  width: 48px;
  height: 48px;
  ${marginB(24)}
`,
);

export const CancelButton = styled.div`
  cursor: pointer;
`;
