import { AccessControlRules } from './config';
import { Action, Permission, Resource } from './enums';

type RuleFactoryType = 'fullStack' | 'smallBizStack';

export class RuleFactory {
  static fullStack(): AccessControlRules {
    return {
      [Resource.All]: { [Action.ALL]: Permission.ALLOW },
    };
  }

  static smallBizStack(): AccessControlRules {
    return {
      [Resource.OrderMonitoring]: { [Action.READ]: Permission.ALLOW },
      [Resource.OrderMonitoringList]: { [Action.READ]: Permission.ALLOW },
      [Resource.Analytics]: { [Action.READ]: Permission.ALLOW },
      [Resource.MenuPlanner]: { [Action.READ]: Permission.DENY },
      [Resource.FacilitySettings]: { [Action.READ]: Permission.ALLOW },
      [Resource.Brands]: { [Action.READ]: Permission.ALLOW },
      [Resource.BrandsProfile]: { [Action.READ]: Permission.ALLOW },
      [Resource.BrandsList]: { [Action.READ]: Permission.ALLOW },
      [Resource.FacilitySettingsProfile]: {
        [Action.READ]: Permission.ALLOW,
      },
      [Resource.FacilitySettingsOperationHours]: {
        [Action.READ]: Permission.ALLOW,
      },
      [Resource.FacilitySettingsBrandSorting]: {
        [Action.READ]: Permission.ALLOW,
      },
      [Resource.FacilitySettingsTaxCalculations]: {
        [Action.READ]: Permission.ALLOW,
      },
      [Resource.FacilitySettingsPlatformSettings]: {
        [Action.READ]: Permission.ALLOW,
      },
      [Resource.AnalyticsDailySales]: { [Action.READ]: Permission.ALLOW },
      [Resource.AnalyticsBrandSales]: { [Action.READ]: Permission.ALLOW },
      [Resource.AnalyticsItemisedTransactions]: {
        [Action.READ]: Permission.ALLOW,
      },
      [Resource.AnalyticsItemTransactions]: {
        [Action.READ]: Permission.ALLOW,
      },
      [Resource.AnalyticsTransaction]: { [Action.READ]: Permission.ALLOW },
      [Resource.AnalyticsPartnerRemittance]: {
        [Action.READ]: Permission.ALLOW,
      },
      [Resource.AnalyticsPowerBi]: {
        [Action.READ]: Permission.DENY,
      },
    };
  }

  static create(type: RuleFactoryType): AccessControlRules {
    switch (type) {
      case 'fullStack':
        return this.fullStack();
      case 'smallBizStack':
        return this.smallBizStack();
      default:
        return {};
    }
  }
}
