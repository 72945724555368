import React from 'react';

import { useLocalStorage } from 'common/hooks/useLocalStorage';
import { KDSSettingsKey } from 'utils/screenSetup';

interface Props {
  isAutoPrintOn: boolean;
  setIsAutoPrintOn: (isAutoPrintOn: boolean) => void;
  isOrderSoundOn: boolean;
  setIsOrderSoundOn: (isAutoPrintOn: boolean) => void;
  isShowBrandNameOn: boolean;
  setIsShowBrandNameOn: (isShowBrandNameOn: boolean) => void;
}
export const KDSSettingsContext = React.createContext<Props>({
  isAutoPrintOn: false,
  setIsAutoPrintOn: () => {},
  isOrderSoundOn: false,
  setIsOrderSoundOn: () => {},
  isShowBrandNameOn: false,
  setIsShowBrandNameOn: () => {},
});

type ProviderProps = {
  children: React.ReactNode;
};

export const KDSSettingsProvider = ({ children }: ProviderProps) => {
  const [isAutoPrintOn, setIsAutoPrintOn] = useLocalStorage<boolean>(
    KDSSettingsKey.AutoPrint,
    false,
  );

  const [isOrderSoundOn, setIsOrderSoundOn] = useLocalStorage<boolean>(
    KDSSettingsKey.OrderSound,
    false,
  );

  const [isShowBrandNameOn, setIsShowBrandNameOn] = useLocalStorage<boolean>(
    KDSSettingsKey.BrandName,
    true,
  );

  return (
    <KDSSettingsContext.Provider
      value={{
        isAutoPrintOn,
        setIsAutoPrintOn,
        isOrderSoundOn,
        setIsOrderSoundOn,
        isShowBrandNameOn,
        setIsShowBrandNameOn,
      }}
    >
      {children}
    </KDSSettingsContext.Provider>
  );
};
