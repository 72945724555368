import React, { lazy } from 'react';
import { Redirect, Switch } from 'react-router-dom';

import { useAuthContext } from 'authContext';
import { Type } from 'manager/pages/kitchen';
import { PrivateRoute } from 'router/PrivateRoute';
import { Routes } from 'router/routes';

const AddMealPack = lazy(() => import('./pages/meal-pack-form'));
const Analytics = lazy(() => import('./pages/analytics'));
const AnalyticsAdd = lazy(() => import('./pages/analytics-add'));
const AnalyticsEdit = lazy(() => import('./pages/analytics-edit'));
const Content = lazy(() => import('./pages/content'));

const Customer = lazy(() => import('./pages/customer'));
const Customers = lazy(() => import('./pages/customers'));
const Orders = lazy(() => import('./pages/orders'));

const Kitchen = lazy(() => import('./pages/kitchen'));
const KitchenOperationHours = lazy(
  () => import('./pages/kitchen-operation-hours'),
);
const Kitchens = lazy(() => import('./pages/kitchens'));

const CraveMenu = lazy(() => import('./pages/crave-menu'));
// const MealPacks = lazy(() => import('./pages/meal-packs')); // TODO: Disabled for now
const Modifiers = lazy(() => import('./pages/modifiers'));
const Promos = lazy(() => import('./pages/promos'));
const AddPromo = lazy(() => import('./pages/add-promo'));
const UpdatePromo = lazy(() => import('./pages/promo'));
const NewKitchen = lazy(() => import('./pages/new-kitchen'));
const OperationHours = lazy(() => import('./pages/operation-hours'));
const FacilityProfile = lazy(() => import('./pages/facility-profile'));
const TaxCalculations = lazy(() => import('./pages/tax-calculations'));
const BrandsReordering = lazy(() => import('./pages/brands-reordering'));
const Platforms = lazy(() => import('./pages/platforms'));
const Categories = lazy(() => import('./pages/categories'));

/* MENU PLANNER */
const MenuPlannerAll = lazy(() => import('./pages/menu-planner-all'));
const NewMenuSchedule = lazy(() => import('./pages/new-menu-schedule'));
const SpecialMenuScheduleUpsert = lazy(
  () => import('./pages/menu-schedule-special-create'),
);
const MenuPlannerSingleKitchen = lazy(
  () => import('./pages/menu-planner-single-kitchen'),
);
const OrderMethodOverrideUpsert = lazy(
  () => import('./pages/order-method-override-upsert'),
);
const MenuPlannerOrderMethodsOverrides = lazy(
  () => import('./pages/order-method-overrides'),
);
const MenuPlannerSpecials = lazy(() => import('./pages/menu-planner-specials'));

const MenuForm = lazy(() => import('./pages/menu-form'));
const DriverManagement = lazy(() => import('./pages/driver-management'));
const ToKds = lazy(() => import('./pages/to-kds'));
const QrCode = lazy(() => import('./pages/qr-code'));
const UserManagement = lazy(() => import('./pages/user-management'));
const UserDetails = lazy(() => import('./pages/user-details'));

export const ManagerRouter = () => {
  const { user } = useAuthContext();

  return (
    <Switch>
      <PrivateRoute
        exact
        component={UserManagement}
        {...Routes.USER_MANAGEMENT}
      />
      <PrivateRoute exact component={UserDetails} {...Routes.USER_DETAILS} />
      <PrivateRoute exact component={Orders} {...Routes.ORDERS} />
      <PrivateRoute exact component={Analytics} {...Routes.ANALYTICS} />
      <PrivateRoute exact component={AnalyticsAdd} {...Routes.ANALYTICS_ADD} />
      <PrivateRoute
        exact
        component={AnalyticsEdit}
        {...Routes.ANALYTICS_EDIT}
      />
      <PrivateRoute exact component={Content} {...Routes.CONTENT_MARKETING} />
      <PrivateRoute
        exact
        component={Content}
        {...Routes.CONTENT_MARKETING_EDIT}
      />
      <PrivateRoute
        exact
        component={Content}
        {...Routes.CONTENT_EMBEDDED_REPORTS}
      />

      {/* KITCHEN */}
      <PrivateRoute exact component={Kitchens} {...Routes.KITCHENS} />
      <PrivateRoute
        exact
        render={() => <Kitchen type={Type.Profile} />}
        {...Routes.KITCHEN}
      />
      <PrivateRoute
        exact
        render={() => <Kitchen type={Type.Menu} />}
        {...Routes.MENU}
      />
      <PrivateRoute
        exact
        render={() => <Kitchen type={Type.ActiveOrders} />}
        {...Routes.RO_ACTIVE_ORDERS}
      />
      <PrivateRoute
        exact
        render={() => <NewKitchen />}
        {...Routes.KITCHEN_ADD}
      />
      <PrivateRoute
        exact
        component={KitchenOperationHours}
        {...Routes.KITCHEN_OPERATION_HOURS}
      />

      <PrivateRoute exact component={MenuForm} {...Routes.MENU_ADD} />
      <PrivateRoute exact component={MenuForm} {...Routes.MENU_EDIT} />
      <PrivateRoute exact component={CraveMenu} {...Routes.MENU_ORDER} />
      <PrivateRoute exact component={AddMealPack} {...Routes.ADD_MEAL_PACK} />
      <PrivateRoute exact component={AddMealPack} {...Routes.EDIT_MEAL_PACK} />
      <PrivateRoute exact component={AddMealPack} {...Routes.ADD_SINGLE_MP} />
      <PrivateRoute exact component={AddMealPack} {...Routes.EDIT_SINGLE_MP} />
      {/* TODO: This disabled for now because it's not being utilized */}
      {/* <PrivateRoute exact component={MealPacks} {...Routes.MEAL_PACKS} /> */}
      <PrivateRoute exact component={Modifiers} {...Routes.MODIFIERS} />
      <PrivateRoute exact component={Categories} {...Routes.CATEGORIES} />
      <PrivateRoute exact component={Customers} {...Routes.CUSTOMERS} />
      <PrivateRoute
        exact
        render={() => <Customer type="Profile" />}
        {...Routes.CUSTOMER_PROFILE}
      />
      <PrivateRoute
        exact
        render={() => <Customer type="Orders" />}
        {...Routes.CUSTOMER_ORDERS}
      />
      <PrivateRoute
        exact
        render={() => <Customer type="Addresses" />}
        {...Routes.CUSTOMER_ADDRESSES}
      />
      <PrivateRoute exact component={Promos} {...Routes.PROMOS} />
      <PrivateRoute exact component={AddPromo} {...Routes.ADD_PROMO} />
      <PrivateRoute exact component={UpdatePromo} {...Routes.UPDATE_PROMO} />

      {/* MENU PLANNER */}
      <PrivateRoute
        exact
        component={MenuPlannerSpecials}
        {...Routes.MENU_PLANNER_SPECIALS}
      />
      <PrivateRoute
        exact
        component={MenuPlannerSingleKitchen}
        {...Routes.MENU_PLANNER_SINGLE_KITCHEN}
      />
      <PrivateRoute
        exact
        component={NewMenuSchedule}
        {...Routes.MENU_PLANNER_ADD}
      />
      <PrivateRoute
        exact
        component={NewMenuSchedule}
        {...Routes.MENU_PLANNER_EDIT}
      />
      <PrivateRoute
        exact
        component={MenuPlannerAll}
        {...Routes.MENU_PLANNER_ALL}
      />
      <PrivateRoute
        exact
        component={SpecialMenuScheduleUpsert}
        {...Routes.MENU_PLANNER_SPECIAL_CREATE}
      />
      <PrivateRoute
        exact
        component={SpecialMenuScheduleUpsert}
        {...Routes.MENU_PLANNER_SPECIAL_EDIT}
      />

      {/* FACILITY SETTINGS */}
      <PrivateRoute
        exact
        component={OrderMethodOverrideUpsert}
        {...Routes.ORDER_METHOD_OVERRIDE_ADD}
      />
      <PrivateRoute
        exact
        component={OrderMethodOverrideUpsert}
        {...Routes.ORDER_METHOD_OVERRIDE_EDIT}
      />
      <PrivateRoute
        exact
        component={MenuPlannerOrderMethodsOverrides}
        {...Routes.ORDER_METHODS_OVERRIDES}
      />
      <PrivateRoute
        exact
        component={OperationHours}
        {...Routes.FACILITY_OPERATION_HOURS}
      />
      <PrivateRoute
        exact
        component={FacilityProfile}
        {...Routes.FACILITY_PROFILE}
      />
      <PrivateRoute
        exact
        component={TaxCalculations}
        {...Routes.FACILITY_TAX_CALCULATIONS}
      />
      <PrivateRoute
        exact
        component={BrandsReordering}
        {...Routes.BRANDS_REORDERING}
      />
      <PrivateRoute
        exact
        component={Platforms}
        {...Routes.FACILITY_PLATFORMS}
      />

      <PrivateRoute
        exact
        component={DriverManagement}
        {...Routes.DRIVER_MANAGEMENT}
      />
      <PrivateRoute exact component={ToKds} {...Routes.KDS} />
      <PrivateRoute exact component={QrCode} {...Routes.QRCODE} />
      {user && <Redirect to={Routes.ORDERS.path} />}
    </Switch>
  );
};
