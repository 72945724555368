import React from 'react';

import {
  KitchenMenuItemStatus,
  useUpdateKitchenMenuItemStatusMutation,
} from 'codegen/generated/graphql';
import { Row, Col } from 'common/components/Layout';
import { Switch } from 'common/components/Switch';
import { Text } from 'common/components/Text';

interface Props {
  id: string;
  status: KitchenMenuItemStatus;
  disabled?: boolean;
}

export const ActiveStatus = ({ id, status, disabled }: Props) => {
  const [updateMenuStatus] = useUpdateKitchenMenuItemStatusMutation();

  return (
    <Row alignItems="center">
      <Col alignItems="center" justifyContent="flex-end">
        <Row alignItems="center">
          <Text marginRight="lg" marginLeft="lg">
            Active
          </Text>
          <Switch
            negativeColor="smokeWhite"
            isActive={status === KitchenMenuItemStatus.Active}
            disabled={disabled}
            onClick={() => {
              if (!disabled)
                updateMenuStatus({
                  variables: {
                    id,
                    status:
                      status === KitchenMenuItemStatus.Active
                        ? KitchenMenuItemStatus.Inactive
                        : KitchenMenuItemStatus.Active,
                  },
                });
            }}
          ></Switch>
        </Row>
      </Col>
    </Row>
  );
};
