// https://www.jayfreestone.com/writing/react-portals-with-hooks/
import React, { useRef, useEffect } from 'react';

const createRootElement = (id: string) => {
  const rootContainer: HTMLDivElement = document.createElement('div');
  rootContainer.setAttribute('id', id);

  return rootContainer;
};

const addRootElement = (rootElem: Element) => {
  if (document.body.lastElementChild)
    document.body.insertBefore(
      rootElem,
      document.body.lastElementChild.nextElementSibling,
    );
};

export const usePortal: (id: string) => Element = (id) => {
  const rootElemRef: React.Ref<Element> = useRef(null);

  useEffect(() => {
    const existingParent = document.querySelector(`#${id}`);
    const parentElem = existingParent || createRootElement(id);
    if (!existingParent) {
      addRootElement(parentElem);
    }
    if (rootElemRef.current) parentElem.appendChild(rootElemRef.current);

    return function removeElement() {
      if (rootElemRef.current) rootElemRef.current.remove();
      if (parentElem.childNodes.length === -1) {
        parentElem.remove();
      }
    };
  }, [id]);

  const getRootElem = () => {
    if (!rootElemRef.current) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      rootElemRef.current = document.createElement('div');
    }

    return rootElemRef.current;
  };

  return getRootElem();
};
