import React, { lazy } from 'react';
import { Redirect, Switch } from 'react-router-dom';

import { useAuthContext } from 'authContext';
import { AuthenticatedUserRedirect } from 'router/AuthenticatedUserRedirect';
import { PublicRoute } from 'router/PublicRoute';
import { Routes } from 'router/routes';

const Login = lazy(() => import('common/pages/Login/Login'));
const ForgotPassword = lazy(
  () => import('common/pages/ForgotPassword/ForgotPassword'),
);
const ResetPassword = lazy(
  () => import('common/pages/ResetPassword/ResetPassword'),
);

export const CommonRouter = () => {
  const { user } = useAuthContext();

  return (
    <Switch>
      <PublicRoute exact path={Routes.LOGIN.path} component={Login} />
      <PublicRoute
        exact
        path={Routes.FORGOT_PASSWORD.path}
        component={ForgotPassword}
      />
      <PublicRoute
        exact
        path={Routes.RESET_PASSWORD.path}
        component={ResetPassword}
      />
      {!user && <Redirect to={Routes.LOGIN.path} />}
      {user && <AuthenticatedUserRedirect user={user} />}
    </Switch>
  );
};
