import React from 'react';
import { useLocation, Link, useHistory } from 'react-router-dom';

import { ContextFacility, useAuthContext } from 'authContext';
import { Text } from 'common/components/Text';
import { ActionMenu } from 'manager/components/ActionMenu';
import { Routes, NON_REDIRECT_URLS } from 'router/routes';

import { HelpCenterIcon } from './SidebarIcon';
import { Resource } from '../../../router/AccessControlConfig';

import {
  Wrapper,
  IconWrapper,
  TextWrapper,
  ToggleWrapper,
  SidebarBottomWrapper,
  ItemsWrapper,
} from './styles';

export type SidebarItem = {
  id: string;
  icon: React.ReactElement;
  href: string;
  baseUrl: string;
  title: string;
  isHidden?: boolean;
  resource?: Resource;
};

interface Props {
  items: SidebarItem[];
}

const shouldRedirect = (items: SidebarItem[], pathname: string) =>
  !items.some((item) => item.href === pathname) &&
  !NON_REDIRECT_URLS.includes(pathname);

export const FacilitySelector = ({ items }: { items: SidebarItem[] }) => {
  const { push } = useHistory();
  const { user, selectedFacility, setFacilityById } = useAuthContext();

  const facilityName = selectedFacility?.name;
  const formatFacilities = (facilities: ContextFacility[]) =>
    facilities.map((facility) => ({
      label: facility.name,
      id: facility.id,
      onClick: async () => {
        if (selectedFacility?.id !== facility.id) {
          setFacilityById(facility.id);
          if (shouldRedirect(items, location.pathname))
            push(Routes.ORDERS.path);
        }
      },
    }));
  const facilities = user?.facilities ? formatFacilities(user.facilities) : [];

  return (
    <ToggleWrapper>
      <ActionMenu
        locationToggle
        leftAlign
        actions={facilities}
        selectedFacilityId={selectedFacility?.id}
      />
      <TextWrapper>
        <Text fontSize="xs" fontWeight="bold" marginTop={10}>
          {facilityName}
        </Text>
      </TextWrapper>
    </ToggleWrapper>
  );
};

export const Sidebar = ({ items }: Props) => {
  const location = useLocation();

  return (
    <Wrapper>
      <FacilitySelector items={items} />

      <ItemsWrapper>
        {items.map(({ id, icon, href, baseUrl, title }) => {
          const isActive = location.pathname.includes(baseUrl);

          return (
            <Link to={href} key={id} title={title}>
              <IconWrapper active={isActive}>
                {React.cloneElement(icon, {
                  active: isActive,
                })}
              </IconWrapper>
            </Link>
          );
        })}
      </ItemsWrapper>

      <SidebarBottomWrapper>
        <Link
          to={{
            pathname:
              'https://cravetech.atlassian.net/servicedesk/customer/portals',
          }}
          target="_blank"
          title="Help Center"
        >
          <IconWrapper>
            <HelpCenterIcon />
          </IconWrapper>
        </Link>
      </SidebarBottomWrapper>
    </Wrapper>
  );
};
