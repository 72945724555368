import React, { useState } from 'react';

import { Checkbox } from 'antd';
import { flatMap, uniqBy } from 'lodash';

import { KitchenMenuItem, MenuCategory } from 'codegen/generated/graphql';
import { Dropdown } from 'common/components/Dropdown';
import { Grid, Row } from 'common/components/Layout';
import { Text } from 'common/components/Text';
import { themeColors } from 'common/theme/constants';
import { FilterBox } from 'manager/components/FilterBox';

import { KitchenListItem } from '../types';

import { CheckBoxWrapper, TextWrapper, ClearWrapper } from '../styles';

const getCount = (categoryId: string, items: KitchenMenuItem[]) =>
  items.filter((item) =>
    item.menuCategories.some((menuCategory) => menuCategory.id === categoryId),
  ).length;

interface Props {
  items: KitchenListItem[];
  onCategorySelect: (categories: string[]) => void;
  mode: 'list' | 'grid';
}

export const Categories = ({
  items,
  onCategorySelect,
  mode = 'grid',
}: Props) => {
  const [selectedCategories, setSelectedCategories] = useState<string[]>([]);
  const [selectedCategoryName, setSelectedCategoryName] = useState<string[]>(
    [],
  );

  // only show categories that are in use by menu items on that restaurant page
  let categories: MenuCategory[] = [];
  categories = uniqBy(flatMap(items, 'menuCategories'), 'id');

  const filters = categories
    .map((category) => ({
      id: category.id,
      count: getCount(category.id, items),
      title: category.name,
      type: category.categoryType,
    }))
    .sort((a, b) => a.title.localeCompare(b.title));

  const onFilterSelect = (id: MenuCategory['id'], title: string) => {
    const categories = selectedCategories.includes(id)
      ? selectedCategories.filter((category) => category !== id)
      : [...selectedCategories, id];
    setSelectedCategories(categories);
    onCategorySelect(categories);

    const names = selectedCategoryName.includes(title)
      ? selectedCategoryName.filter((cat) => cat !== title)
      : [...selectedCategoryName, title];
    setSelectedCategoryName(names);
  };
  const categoryName = selectedCategoryName.toString();
  const placeholder = categoryName.length ? categoryName : 'Categories';

  return mode === 'list' ? (
    <Dropdown
      placeholder={placeholder}
      closeOnSelect={false}
      closeOnClickOutside={false}
    >
      <CheckBoxWrapper>
        {filters.map((filter, i) => (
          <Row key={i} direction="row" alignItems="end">
            <Checkbox
              key={filter.id}
              onChange={() => onFilterSelect(filter.id, filter.title)}
              value={filter.id}
              checked={selectedCategories.includes(filter.id)}
            >
              {filter.title}
            </Checkbox>
            <TextWrapper>
              <Text marginRight="xs">{filter.type}</Text>
              <Text
                color={[themeColors.primaryText, 0.55]}
                fontWeight="bold"
                alignSelf="end"
              >
                {`${filter.count}x`}
              </Text>
            </TextWrapper>
          </Row>
        ))}
        <ClearWrapper
          onClick={() => {
            onFilterSelect('', '');
            setSelectedCategories(['']);
            setSelectedCategoryName(['']);
          }}
        >
          <Text alignSelf="center" fontWeight="bold">
            Clear Selection
          </Text>
        </ClearWrapper>
      </CheckBoxWrapper>
    </Dropdown>
  ) : (
    <Grid
      gridColumns="repeat(6,auto)"
      gridGap={26}
      paddingB="md"
      aria-label="menu grid"
    >
      {filters.map((filter) => (
        <FilterBox
          key={filter.id}
          count={filter.count}
          label={filter.title}
          isActive={selectedCategories.includes(filter.id)}
          onClick={() => onFilterSelect(filter.id, filter.title)}
        />
      ))}
    </Grid>
  );
};
