import React, { useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import {
  useExpanded,
  useGroupBy,
  usePagination,
  useSortBy,
  useTable,
} from 'react-table';

import { SortOrderArg } from 'codegen/generated/graphql';
import { Row } from 'common/components/Layout';

import { DnDRows } from './components/DnDRows';
import { TablePagination } from './components/Pagination';
import { Rows } from './components/Rows';
import { TableHeader } from './components/TableHeader';
import { DnDProps, TableData, TableProps } from './types';
import { LoaderSimple } from '../Loader';

import { Wrapper } from './styles';

export function Table<Values extends TableData = TableData>({
  loading = false,
  hasSeparateRows,
  showHeaders = true,
  lightHeader = false,
  simple,
  columns,
  data,
  columnsWidth,
  initialPage,
  initialSort,
  onPageChange,
  pageSize,
  manualPageCount,
  onSortChange,
  paginationDisabled,
  isDnDSortEnabled = false,
  updateSort,
  alternateRowColor = false,
  hiddenColumns = undefined,
  isScrollBarVisible = false,
}: TableProps<Values> & DnDProps) {
  const location = useLocation();
  const params = useMemo(
    () => new URLSearchParams(location.search),
    [location],
  );

  const columnsConfig = useMemo(
    () =>
      columns.map((c) => ({
        ...c,
        disableSortBy: !c.defaultCanSort,
        disableGroupBy: !c.defaultCanGroupBy,
      })),
    [columns],
  );

  const {
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    headers,
    // setPageSize,
    state: {
      pageIndex,
      sortBy,
      // pageSize
    },
    setHiddenColumns,
  } = useTable(
    {
      columns: columnsConfig,

      data,
      initialState: {
        pageIndex: initialPage ?? 0,
        sortBy: initialSort || [],
        pageSize: pageSize ?? 15,
        hiddenColumns: hiddenColumns || [],
      },
      defaultCanSort: false,
      ...(!!manualPageCount && {
        manualPagination: true,
        pageCount: manualPageCount,
      }),
    },
    useGroupBy,
    useSortBy,
    useExpanded,
    usePagination,
  );

  useEffect(() => {
    setHiddenColumns(
      hiddenColumns && hiddenColumns.length > 0 ? hiddenColumns : [],
    );
  }, [hiddenColumns, setHiddenColumns]);

  useEffect(() => {
    if (!onSortChange) return;

    const by = params.get('by') as string;
    const isDesc = params.get('sort') === SortOrderArg.Desc;
    const hasSameOrderBy = by !== sortBy[0]?.id;
    const isSortedDesc = sortBy[0]?.desc !== isDesc;

    if (sortBy.length && (hasSameOrderBy || isSortedDesc)) {
      onSortChange({
        dataKey: sortBy[0].id,
        direction: sortBy[0].desc ? SortOrderArg.Desc : SortOrderArg.Asc,
      });
    }
  }, [sortBy, onSortChange, params]);

  const rowProps = {
    rows: page,
    simple,
    hasSeparateRows,
    columns: columnsWidth,
    prepareRow,
    alternateRowColor,
  };

  const paginationProps = {
    onPageChange,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    pageIndex,
  };

  const tableProps = { columnsWidth, lightHeader, columns, data };

  return (
    <>
      <Row isScrollBarVisible={isScrollBarVisible}>
        <Wrapper
          columns={columnsWidth}
          simple={simple}
          hasSeparateRows={hasSeparateRows}
          showHeaders={showHeaders}
        >
          {showHeaders && <TableHeader headers={headers} {...tableProps} />}
          {loading && <LoaderSimple height="50vh" />}
          {!loading && isDnDSortEnabled ? (
            <DnDRows updateSort={updateSort} {...rowProps} />
          ) : (
            <Rows {...rowProps} />
          )}
        </Wrapper>
      </Row>
      {!paginationDisabled && <TablePagination {...paginationProps} />}
    </>
  );
}
