import React, { Suspense } from 'react';
import { Switch, Route } from 'react-router-dom';

import { CommonRouter } from 'common';
import { DeveloperRouter } from 'developer';
import { KDSRouter, FOHOnlyRouter, OrderStatusOnlyRouter } from 'facility';
import { ManagerRouter } from 'manager';

import { useAuthContext } from 'authContext';
import { UserRole } from 'codegen/generated/graphql';
import { Loader } from 'common/components';

export const Router = () => {
  const { loading, user } = useAuthContext();

  if (loading) {
    return <Loader fullScreen />;
  } else if (user?.roles.includes(UserRole.EmployeeOrderStatus)) {
    return <OrderStatusOnlyRouter />;
  } else if (user?.roles.includes(UserRole.EmployeeFoh)) {
    return <FOHOnlyRouter />;
  }

  return (
    <Suspense fallback={<Loader fullScreen />}>
      <Switch>
        <Route path="/developer">
          <DeveloperRouter />
        </Route>
        <Route path="/manager">
          <ManagerRouter />
        </Route>
        <Route path="/facility">
          <KDSRouter />
        </Route>
        <Route path="/">
          <CommonRouter />
        </Route>
      </Switch>
    </Suspense>
  );
};
