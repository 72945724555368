import React, { useMemo } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import { useAccessControlContext } from '../../../contexts/AccessControlContext/accessControlContext';
import { useAuthContext } from 'authContext';
import { UserRole } from 'codegen/generated/graphql';
import { ButtonTabs } from 'manager/components/ButtonTabs';
import { Props as RestaurantPageProps, Type } from 'manager/pages/kitchen';
import { Resource, Action } from 'router/AccessControlConfig';
import { Routes } from 'router/routes';
import { LogEvent } from 'utils/analytics';
import { getRoute } from 'utils/getRoute';

type Link = {
  label: string;
  value: string;
  disabled: boolean;
  allowedRoles: UserRole[];
  'data-cy'?: string;
  isHidden?: boolean;
};

export const KitchenNavigation = ({ type }: RestaurantPageProps) => {
  const { user } = useAuthContext();
  const { isGranted } = useAccessControlContext();
  const { kitchenId } = useParams<{ kitchenId: string }>();
  const { push } = useHistory();
  const location = useLocation();

  const updateLinks: Link[] = useMemo(() => {
    return [
      {
        label: 'Profile',
        value: getRoute(Routes.KITCHEN.path, kitchenId),
        disabled: false,
        allowedRoles: Routes.KITCHEN.allowedRoles,
        isHidden: !isGranted({
          resource: Resource.BrandsProfile,
          action: Action.READ,
        }),
      },
      {
        label: 'Menu Items',
        value: getRoute(Routes.MENU.path, kitchenId),
        disabled: false,
        allowedRoles: Routes.MENU.allowedRoles,
        isHidden: !isGranted({
          resource: Resource.BrandsMenuItems,
          action: Action.READ,
        }),
      },
      {
        label: 'Modifiers',
        value: getRoute(Routes.MODIFIERS.path, kitchenId),
        disabled: false,
        allowedRoles: Routes.MODIFIERS.allowedRoles,
        isHidden: !isGranted({
          resource: Resource.BrandsModifiers,
          action: Action.READ,
        }),
      },
      {
        label: 'Operation Hours',
        value: getRoute(Routes.KITCHEN_OPERATION_HOURS.path, kitchenId),
        disabled: false,
        allowedRoles: Routes.KITCHEN_OPERATION_HOURS.allowedRoles,
        isHidden: !isGranted({
          resource: Resource.BrandsOperationHours,
          action: Action.READ,
        }),
      },
      {
        label: 'Active Orders',
        value: getRoute(Routes.RO_ACTIVE_ORDERS.path, kitchenId),
        disabled: false,
        allowedRoles: Routes.RO_ACTIVE_ORDERS.allowedRoles,
        isHidden: !isGranted({
          resource: Resource.BrandsActiveOrders,
          action: Action.READ,
        }),
      },
    ];
  }, [isGranted, kitchenId]);

  const addLinks: Link[] = [
    {
      label: 'Profile',
      value: Routes.KITCHEN_ADD.path,
      disabled: false,
      allowedRoles: Routes.KITCHEN_ADD.allowedRoles,
    },
    {
      label: 'Menu',
      value: Routes.KITCHENS.path,
      disabled: true,
      allowedRoles: Routes.KITCHEN.allowedRoles,
    },
  ];

  const links = (
    type === Type.MealPacks ? [] : type === Type.Add ? addLinks : updateLinks
  ).filter((link) =>
    link.allowedRoles.some((allowedRole) => user?.roles.includes(allowedRole)),
  );

  return (
    <ButtonTabs
      options={links}
      data-cy="admin-Restaurnts-Topmenu"
      value={location.pathname}
      onChange={async (value) => {
        push(value);
        await LogEvent('RESTAURANT_MANAGEMENT', {
          message: `navigated to "${value}"`,
          id: kitchenId,
        });
      }}
      checkPartially
    />
  );
};
