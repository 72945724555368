import styled from 'styled-components';

import { Row } from 'common/components/Layout';

export const Container = styled(Row).attrs({
  background: 'white',
  paddingY: 30,
  paddingX: 40,
  borderRadius: 'normal',
  direction: 'column',
})``;
