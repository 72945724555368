import React from 'react';

import { Icon, IconSize } from 'common/components/Icon';
import { IconName } from 'common/components/Icon/types';
import { Text } from 'common/components/Text';
import { themeColors } from 'common/theme/constants';

import * as S from './styles';

export const NetworkInfoControls = ({
  iconName,
  text,
  onClick,
}: {
  iconName: IconName;
  text: string;
  onClick: () => void;
}) => (
  <S.NetworkInfoControlWrapper
    padding={30}
    borderRadius="xxl"
    width="max-content"
    height="60px"
    alignItems="center"
    cursorPointer
    onClick={onClick}
  >
    <Icon
      name={iconName}
      size={IconSize.MEDIUM}
      iconColor={themeColors.white}
    />
    <Text
      fontSize={'bodyHeader'}
      color={themeColors.white}
      alignSelf="center"
      marginRight={10}
      marginLeft={10}
      fontWeight={'bold'}
    >
      {text}
    </Text>
  </S.NetworkInfoControlWrapper>
);
