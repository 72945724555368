import React from 'react';

import { MenuItemAvailability } from 'codegen/generated/graphql';
import { Dropdown } from 'common/components/Dropdown';

type Props = {
  onAvailSelect: (available: string[]) => void;
};

export const Visibility = ({ onAvailSelect }: Props) => {
  const options = [
    {
      value: MenuItemAvailability.Catering,
      label: 'Catering website',
    },
    {
      value: MenuItemAvailability.Consumer,
      label: 'Consumer (Web & App)',
    },
    {
      value: MenuItemAvailability.Kiosk,
      label: 'Kiosk',
    },
    { value: '', label: 'Clear Selection', clearOption: true },
  ];

  const onSelect = (value: string[]) => {
    onAvailSelect(value);
  };

  return (
    <Dropdown
      options={options}
      placeholder="Filter platforms"
      callback={(o) => onSelect(o.value)}
      closeOnSelect={true}
      closeOnClickOutside={false}
    />
  );
};
