import React, { lazy } from 'react';
import { Redirect, Switch } from 'react-router-dom';

import { useAuthContext } from 'authContext';
import { PrivateRoute } from 'router/PrivateRoute';
import { Routes } from 'router/routes';

const DeveloperTools = lazy(() => import('./pages/developer-tools'));

export const DeveloperRouter = () => {
  const { user } = useAuthContext();

  return (
    <Switch>
      <PrivateRoute
        exact
        component={DeveloperTools}
        {...Routes.DEVELOPER_TOOLS}
      />
      {user && <Redirect to={Routes.DEVELOPER_TOOLS.path} />}
    </Switch>
  );
};
