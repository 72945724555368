import { Property as CsstypeProperty } from 'csstype';
import styled, { BorderRadius, Shadows } from 'styled-components';

import {
  background,
  FlexProps,
  flex,
  flexWrap,
  display,
  padding,
  paddingL,
  paddingR,
  paddingT,
  paddingB,
  paddingX,
  paddingY,
  borderRadiusTR,
  borderRadiusBL,
  borderRadiusBR,
  borderRadiusTL,
  radius,
  alignItems,
  justifyContent,
  AlignItemsProps,
  JustifyContentProps,
  gridColumn,
  gridGap,
  gridRowGap,
  gridColumnGap,
  shadow,
  gridTemplateAreas,
  marginT,
  marginB,
  marginX,
  marginY,
  marginL,
  marginR,
  margin,
  alignSelf,
  AlignSelfProps,
  SpacingType,
  ColorProps,
  borderB,
  borderT,
  flexGap,
} from 'common/utils/styleHelpers';

interface ColProps extends CommonProps {
  display?: CsstypeProperty.Display;
  flex?: number | string;
  width?: string;
  minWidth?: string;
  maxWidth?: string;
  cursorPointer?: boolean;
  breakInside?: 'auto' | 'avoid';
  wordBreak?: string;
}

interface GridProps extends CommonProps {
  gridColumns: string;
  gridAreas?: string;
  gridGap?: SpacingType;
  gridRowGap?: SpacingType;
  gridColGap?: SpacingType;
  minHeight?: string;
  width?: string;
  gridFlow?: string;
}

interface CommonProps {
  padding?: SpacingType;
  paddingL?: SpacingType;
  paddingR?: SpacingType;
  paddingT?: SpacingType;
  paddingB?: SpacingType;
  paddingX?: SpacingType;
  paddingY?: SpacingType;
  margin?: SpacingType;
  marginL?: SpacingType;
  marginR?: SpacingType;
  marginT?: SpacingType;
  marginB?: SpacingType;
  marginX?: SpacingType;
  marginY?: SpacingType;
  flexWrap?: 'wrap' | 'nowrap';
  background?: ColorProps;
  borderRadius?: keyof BorderRadius;
  radiusTR?: keyof BorderRadius;
  radiusBL?: keyof BorderRadius;
  radiusBR?: keyof BorderRadius;
  radiusTL?: keyof BorderRadius;
  height?: string;
  alignItems?: AlignItemsProps;
  alignSelf?: AlignSelfProps;
  justifyContent?: JustifyContentProps;
  shadow?: keyof Shadows;
  borderBottom?: number;
  borderTop?: number;
}
const commonProps = ({
  padding: p,
  paddingL: pl,
  paddingR: pr,
  paddingT: pt,
  paddingB: pb,
  paddingX: px,
  paddingY: py,
  margin: m,
  marginL: ml,
  marginR: mr,
  marginT: mt,
  marginB: mb,
  marginX: mx,
  marginY: my,
  flexWrap: fw,
  background: backgroundColor,
  borderRadius: br,
  radiusTR,
  radiusBL,
  radiusBR,
  radiusTL,
  height,
  alignItems: ai,
  alignSelf: aS,
  justifyContent: jc,
  shadow: boxShadow,
  borderBottom,
  borderTop,
}: CommonProps) => `
  ${fw ? flexWrap(fw) : ''}
  ${backgroundColor ? background(backgroundColor) : ''}
  ${p ? padding(p) : ''}
  ${pl ? paddingL(pl) : ''}
  ${pr ? paddingR(pr) : ''}
  ${pt ? paddingT(pt) : ''}
  ${pb ? paddingB(pb) : ''}
  ${px ? paddingX(px) : ''}
  ${py ? paddingY(py) : ''}

  ${m ? margin(m) : ''}
  ${ml ? marginL(ml) : ''}
  ${mr ? marginR(mr) : ''}
  ${mt ? marginT(mt) : ''}
  ${mb ? marginB(mb) : ''}
  ${mx ? marginX(mx) : ''}
  ${my ? marginY(my) : ''}

  ${br ? radius(br) : ''}
  ${radiusTR ? borderRadiusTR(radiusTR) : ''}
  ${radiusBL ? borderRadiusBL(radiusBL) : ''}
  ${radiusBR ? borderRadiusBR(radiusBR) : ''}
  ${radiusTL ? borderRadiusTL(radiusTL) : ''}

  ${boxShadow ? shadow(boxShadow) : ''}

  ${height ? `height: ${height};` : ''}

  ${ai ? alignItems(ai) : ''}
  ${aS ? alignSelf(aS) : ''}
  ${jc ? justifyContent(jc) : ''}

  ${typeof borderBottom !== 'undefined' ? borderB(borderBottom) : ''}
  ${typeof borderTop !== 'undefined' ? borderT(borderTop) : ''}

`;

export const Col = styled.div<ColProps>(
  ({
    display: dis = 'inline-flex',
    flex,
    width,
    minWidth,
    maxWidth,
    cursorPointer,
    breakInside = 'auto',
    wordBreak,
    ...rest
  }) => `
  ${display(dis)}
  ${flex ? `flex: ${flex}` : ''};
  ${width ? `width: ${width}` : ''};
  ${minWidth ? `min-width: ${minWidth}` : ''};
  ${maxWidth ? `max-width: ${maxWidth}` : ''};
  ${cursorPointer ? 'cursor: pointer' : ''};
  
  ${commonProps(rest)}
  break-inside: ${breakInside};
  ${wordBreak ? `word-break: ${wordBreak}` : ''};
`,
);

interface RowProps extends CommonProps {
  direction?: FlexProps;
  flexWrap?: 'wrap' | 'nowrap';
  display?: CsstypeProperty.Display;
  breakInside?: 'auto' | 'avoid';
  width?: string;
  flexGap?: SpacingType;
  overflowX?: 'scroll' | 'inherit' | 'auto';
  isScrollBarVisible?: boolean;
}

export const Row = styled.div<RowProps>(
  ({
    direction = 'row',
    flexWrap: wrap = 'wrap',
    display: dis = 'flex',
    breakInside = 'auto',
    width = '100%',
    overflowX = 'inherit',
    flexGap: flexG,
    isScrollBarVisible,
    ...rest
  }) => `
  
  width: ${width};
  break-inside: ${breakInside};
  overflow-x: ${overflowX};
  ::-webkit-scrollbar {
    ${!isScrollBarVisible ? 'display: none' : 'display: block'};
  }
  ${flex(direction)}
  ${flexWrap(wrap)}
  ${display(dis)}
  ${flexG ? flexGap(flexG) : ''}
  ${commonProps(rest)}
`,
);

export const Grid = styled.div<GridProps>(
  ({
    gridColumns,
    width,
    gridGap: gridG,
    gridColGap: gridC,
    gridRowGap: gridR,
    gridAreas,
    minHeight,
    gridFlow,
    ...rest
  }) => `
  ${width ? `width: ${width}` : 'width: 100%'};  
  ${display('grid')}
  ${gridColumn(gridColumns)}
  ${gridAreas ? gridTemplateAreas(gridAreas) : ''}
  ${gridG ? gridGap(gridG) : ''}
  ${gridC ? gridColumnGap(gridC) : ''}
  ${gridR ? gridRowGap(gridR) : ''}
  ${minHeight ? `min-height: ${minHeight}` : ''};
  ${gridFlow ? `grid-auto-flow: ${gridFlow}` : ''};
  ${commonProps(rest)}
`,
);

export const GridTemplateArea = styled.div<{ name: string }>(
  ({ name }) => `
  grid-area: ${name};
`,
);
