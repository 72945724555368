import { CloseCircleTwoTone, CheckCircleTwoTone } from '@ant-design/icons';
import styled from 'styled-components';

import { Icon, IconSize } from 'common/components/Icon';
import {
  background,
  textAlign,
  paddingY,
  radius,
  alignItems,
  display,
} from 'common/utils/styleHelpers';

export const SortIcon = styled(Icon).attrs({
  size: IconSize.SMALL,
  iconColor: 'smokeText',
  name: 'menu',
})``;

export const DragButton = styled.div`
  cursor: grab;
  display: flex;
  align-items: center;
`;

export const SortableWrapper = styled.div`
  width: 100%;
  ul,
  li {
    width: 100%;
  }
`;

export const BrandLogo = styled.img`
  width: 50px;
  height: 50px;
  object-fit: contain;
`;

export const BadgeInput = styled.input.attrs({ type: 'number' })(
  () => `
  width: 60px;
  outline: none;
  border: none;
  ${radius('normal')}
  ${background('beige')}
  ${textAlign('center')}
  ${paddingY(2)}
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
  }
`,
);

export const ImagePreview = styled.img(
  () => `
  ${radius('xSmall')}
  width: 375px;
  height: 280px;
  position: absolute;
  top: 80%;
  left: 3%;
  z-index: 1;
  display: none;

  `,
);
export const Wrapper = styled.div`
  cursor: pointer;
  :hover {
    ${ImagePreview} {
      display: inline;
    }
  }
`;

export const Image = styled.img(
  () => `
  width: 32px;
  height: 32px;
  ${radius('half')}
`,
);

export const ListItemWrapper = styled.div`
  position: relative;
`;

export const CheckBoxWrapper = styled.div`
  padding-left: 10px;
  padding-top: 10px;
  width: 100%;
  position: relative;
  .ant-checkbox + span {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    width: 160px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;
export const TextWrapper = styled.div`
  position: absolute;
  right: 3px;
  display: flex;
  flex-direction: row;
`;

export const ClearWrapper = styled.div`
  height: 40px;
  ${alignItems('center')}
  ${display('inline-flex')}
  position: absolute;
  padding-left: 8px;
  cursor: pointer;
`;

export const RedCloseCircleIcon = styled(CloseCircleTwoTone).attrs({
  twoToneColor: '#CE5454',
})`
  font-size: 20px;
`;

export const GreenCheckCircleIcon = styled(CheckCircleTwoTone).attrs({
  twoToneColor: '#61B98F',
})`
  font-size: 20px;
`;
