import { message } from 'antd';
import * as Yup from 'yup';

import {
  UpsertKitchenMutationFn,
  KitchenStatus,
  Kitchen,
  KitchensDocument,
  KitchensQuery,
  KitchenListDocument,
} from 'codegen/generated/graphql';
import { MultiSelectOptions } from 'manager/components/MultiSelect/MultiSelect';
import { phoneRegExp } from 'utils/helpers';

export const validationSchema = Yup.object().shape({
  name: Yup.string()
    .required('This field is required')
    .test('spaces', 'Empty field is not allowed', (val) =>
      val ? /\S/.test(val) : false,
    ),
  originalLocation: Yup.string()
    .max(40, 'Maximum of 40 characters is allowed')
    .nullable()
    .notRequired(),
  description: Yup.string()
    .required('This field is required')
    .test(
      'len',
      'Maximum of 150 characters is allowed',
      (val) => (val?.length ?? 0) <= 150,
    ),
  phone: Yup.string().matches(phoneRegExp, 'Phone number is not valid'),
  email: Yup.string().email('Email is not valid'),
  status: Yup.string().required('This field is required'),
  story: Yup.string().required('This field is required'),
  cookTime: Yup.number().required('This field is required'),
  cuisine: Yup.string()
    .required('This field is required')
    .test(
      'len',
      'Maximum of 12 characters is allowed',
      (val) => (val?.length ?? 0) <= 12,
    )
    //require at least one non-space value
    .matches(/^(?=.*\S).+$/, 'Text is not valid'),
  enableChowly: Yup.boolean().required('This field is required'),
});

export const initialValues: InitialValues = {
  name: '',
  originalLocation: '',
  story: '',
  description: '',
  cuisine: '',
  phone: '',
  email: '',
  status: KitchenStatus.Active,
  cookTime: 0,
  owners: [],
  enableChowly: false,
};

export type InitialValues = Pick<
  Kitchen,
  'logoUrl' | 'headerImgUrl' | 'status'
> & {
  name: string;
  originalLocation: string;
  story: string;
  description: string;
  cuisine: string;
  phone: string;
  email: string;
  cookTime: number;
  owners: MultiSelectOptions[];
  enableChowly: boolean;
};

interface SubmitProps {
  upsertKitchen: UpsertKitchenMutationFn;
  successModal: (message: string) => void;
  values: InitialValues;
  id?: string;
}

export const submit = async ({
  id,
  values,
  upsertKitchen,
  successModal,
}: SubmitProps) => {
  const { owners, ...restValues } = values;

  try {
    await upsertKitchen({
      variables: {
        input: {
          ...restValues,
          id,
          ownerIds: owners.map(({ id }) => id),
        },
      },
      refetchQueries: [
        {
          query: KitchenListDocument,
        },
      ],
      update: (cache, { data }) => {
        try {
          const existingData = cache.readQuery({
            query: KitchensDocument,
          }) as KitchensQuery;

          cache.writeQuery({
            query: KitchensDocument,
            data: {
              adminKitchens: id
                ? (existingData?.adminKitchens ?? []).map((kitchen) =>
                    kitchen.id === data?.adminUpsertKitchen?.id
                      ? data?.adminUpsertKitchen
                      : kitchen,
                  )
                : [data?.adminUpsertKitchen, ...existingData?.adminKitchens],
            },
          });
        } catch {
          //
        }
      },
    });
    successModal(
      id
        ? `${values.name} kitchen changes has been saved!`
        : `A new kitchen ${values.name} has been added!`,
    );
  } catch {}
};

export const useCopyToClipBoard = () => {
  return async (text: string) => {
    if (!navigator?.clipboard) {
      message.error('Clipboard not supported');

      return false;
    }

    try {
      await navigator.clipboard.writeText(text);
      message.success('Successfully copied!');

      return true;
    } catch (e) {
      if (e instanceof Error) {
        message.error(`'Copy failed:  ${e.message}`);
      }

      return false;
    }
  };
};
