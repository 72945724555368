import styled, { Color, Spacing } from 'styled-components';

import {
  alignSelf,
  background,
  display,
  paddingX,
  paddingY,
  padding,
  marginR,
  marginL,
} from 'common/utils/styleHelpers';

interface StatusBoxProps {
  backgroundColor: keyof Color;
  shape?: Shape;
  marginRight?: keyof Spacing;
  marginLeft?: keyof Spacing;
}

export enum Shape {
  Round = 'round',
}

export const Wrapper = styled.div<StatusBoxProps>(
  ({ backgroundColor, shape, marginRight = 'none', marginLeft = 'none' }) => `
  ${display('inline-flex')}
  ${background(backgroundColor)}
  border-radius: 50px;
  ${alignSelf('center')}
  ${paddingX('md-lg')}
  ${paddingY(6)}
  ${marginR(marginRight)}
  ${marginL(marginLeft)}
  ${shape === Shape.Round && padding(6)}
  ${shape === Shape.Round && paddingX(10)}
  > p {
    width: max-content;
  }

`,
);
