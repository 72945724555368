import React from 'react';

import { Multiselect } from 'multiselect-react-dropdown';

import { StyledMultiSelectDiv } from './styles';

export interface MultiSelectOptions {
  name: string;
  id: string;
  cat?: string;
  facilityId?: string;
}

type Props = {
  options?: MultiSelectOptions[];
  selectedValues?: MultiSelectOptions[];
  onSelect: (selectedList: any, selectedItem?: any) => void;
  onRemove: (selectedList: any) => void;
  reference?: any;
  grouping?: boolean;
  placeholder?: string;
  emptyRecordMsg?: string;
  disabled?: boolean;
  fluid?: boolean;
  singleSelect?: boolean;
  loading?: boolean;
  onSearch?: (value: string) => void;
  showCheckbox?: boolean;
};

export const MultiSelect: React.FC<Props> = ({
  options,
  selectedValues,
  onSelect,
  onRemove,
  reference,
  grouping,
  placeholder,
  emptyRecordMsg,
  disabled,
  fluid,
  singleSelect,
  loading,
  onSearch,
  showCheckbox,
}) => {
  return (
    <StyledMultiSelectDiv fluid={fluid}>
      <Multiselect
        loading={loading}
        options={options}
        selectedValues={selectedValues}
        onSelect={onSelect}
        onRemove={onRemove}
        displayValue="name"
        closeIcon="cancel"
        groupBy={grouping ? 'cat' : undefined}
        closeOnSelect={false}
        disable={disabled}
        ref={reference}
        placeholder={placeholder}
        emptyRecordMsg={emptyRecordMsg}
        onSearch={onSearch}
        singleSelect={singleSelect}
        showCheckbox={showCheckbox}
      />
    </StyledMultiSelectDiv>
  );
};
