import React from 'react';

import { Tooltip as AntdTooltip } from 'antd';

import { themeColors } from 'common/theme/constants';

import { Icon } from '../Icon';
import { Row } from '../Layout';
import { Text } from '../Text';

interface Props {
  text: string;
  disable?: boolean;
  children?: React.ReactNode;
}

export const Tooltip = ({ text, disable, children }: Props) => {
  return (
    <>
      {children}
      <AntdTooltip
        title={
          <Text fontSize="sm" fontWeight="thin">
            {text}
          </Text>
        }
        color="#fff"
      >
        <Row width="fit-content">
          <Icon
            iconColor={disable ? 'smokeWhite' : themeColors.primaryText}
            margin="md"
            name="info_outline"
            size={20}
          />
        </Row>
      </AntdTooltip>
    </>
  );
};
