import * as Sentry from '@sentry/browser';

import { isStaging, isProduction } from './index';

const enableSentry =
  (isStaging() || isProduction()) &&
  !window?.location.hostname.includes('localhost');

export const initSentry = () => {
  return Sentry.init({
    enabled: enableSentry,
    environment: process.env.REACT_APP_CUSTOM_ENV,
    ignoreErrors: [
      'Network Error',
      'Network request failed',
      'Loading chunk',
      "Unexpected token '<'",
    ],
    // buttons onerror message from aria-label tag
    // https://github.com/getsentry/sentry-javascript/issues/577#issuecomment-426929083
    beforeBreadcrumb(breadcrumb, hint) {
      if (breadcrumb.category === 'ui.click') {
        const ariaLabel = hint?.event?.target?.ariaLabel;
        if (ariaLabel) {
          breadcrumb.message = ariaLabel; // eslint-disable-line no-param-reassign
        }
      }

      return breadcrumb;
    },
  });
};
