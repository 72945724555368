import React from 'react';

import { Divider as AntDivider, DividerProps } from 'antd';
import styled from 'styled-components';

import { theme } from 'common/theme';
import { SpacingType, marginT, marginB } from 'common/utils/styleHelpers';

const DividerStyle = styled(AntDivider).withConfig({
  shouldForwardProp: (prop, defaultValidatorFn) =>
    !['marginTop', 'marginBottom'].includes(prop) && defaultValidatorFn(prop),
})<{
  color?: string;
  height?: number;
  width?: string;
  marginTop?: SpacingType;
  marginBottom?: SpacingType;
}>(
  ({ color, height, width, marginTop, marginBottom }) => `
  border-top: ${height}px solid ${color};
  width: ${width};
  min-width: ${width}; 
  ${marginTop && marginT(marginTop)}
  ${marginBottom && marginB(marginBottom)}`,
);

interface Props extends DividerProps {
  color?: string;
  height?: number;
  width?: string;
  marginT?: SpacingType;
  marginB?: SpacingType;
}

/**
 * Divider - from Ant Design
 *
 * @param color - type of Color (default mischka)
 * @param height - number (default 1)
 * @param width - string (default 100%)
 * @param marginT - SpacingType (default sm = 12px)
 * @param marginB - SpacingType (default sm = 12px)
 */
export const Divider = ({
  color = theme.color.mischka,
  height = 1,
  width = '100%',
  marginT = 'sm',
  marginB = 'sm',
  ...props
}: Props) => (
  <DividerStyle
    color={color}
    height={height}
    width={width}
    marginTop={marginT}
    marginBottom={marginB}
    {...props}
  />
);
