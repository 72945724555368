import React from 'react';

import { Button } from 'common/components/Button';

import { Navigation } from './styles';

interface Option {
  label: string | JSX.Element;
  value: string;
  disabled?: boolean;
  badge?: string;
  isHidden?: boolean;
}

interface Props {
  options: Option[];
  value: string | string[];
  onChange: (value: string) => void;
  // Checking if the name is matching partially
  checkPartially?: boolean;
  navFilter?: boolean;
}

export const ButtonTabs = ({
  options,
  value,
  onChange,
  checkPartially,
  navFilter,
}: Props) => (
  <Navigation navFilter={navFilter}>
    {options.map((option) => {
      const isActive =
        option.value === value ||
        (checkPartially && value.indexOf(option.value) > -1) ||
        (Array.isArray(value) && value.indexOf(option.value) > -1);

      if (option.isHidden) return null;

      return (
        <Button
          aria-label="edit restaurant button"
          data-cy="admin-restaurant-topbar-buttons"
          key={`buttontab-${option.value}`}
          className={!isActive ? 'not-active' : navFilter ? 'active' : ''}
          primary
          type="button"
          text={option.label}
          disabled={option.disabled}
          badge={option.badge}
          onClick={() => onChange(option.value)}
        />
      );
    })}
  </Navigation>
);
