import React, { useState } from 'react';

import { SUBSCRIPTIONS_FLAG_ON } from 'utils';

interface Props {
  isSubscriptionsOn: boolean;
  setIsSubscriptionsOn: (isSubscriptionsOn: boolean) => void;
}
export const SubscriptionsContext = React.createContext<Props>({
  isSubscriptionsOn: SUBSCRIPTIONS_FLAG_ON,
  setIsSubscriptionsOn: () => {},
});

type ProviderProps = {
  children: React.ReactNode;
};

export const SubscriptionsContextProvider = ({ children }: ProviderProps) => {
  const varFromSessionStorage = sessionStorage.getItem('isSubscriptionsOn');
  const isVarDefinedInSessionStorage =
    varFromSessionStorage === 'true' || varFromSessionStorage === 'false';
  const initialSubscriptionOn = isVarDefinedInSessionStorage
    ? varFromSessionStorage === 'true'
    : SUBSCRIPTIONS_FLAG_ON;
  const [isSubscriptionsOn, setIsSubscriptionsOn] = useState<boolean>(
    initialSubscriptionOn,
  );

  const setIsSubscriptionsOnInStateAndStorage = (isOn: boolean) => {
    sessionStorage.setItem('isSubscriptionsOn', JSON.stringify(isOn));
    setIsSubscriptionsOn(isOn);
  };

  return (
    <SubscriptionsContext.Provider
      value={{
        isSubscriptionsOn,
        setIsSubscriptionsOn: setIsSubscriptionsOnInStateAndStorage,
      }}
    >
      {children}
    </SubscriptionsContext.Provider>
  );
};
