import React, { ReactNode } from 'react';

import { useAccessControlContext } from '../../../contexts/AccessControlContext/accessControlContext';

import { Action, Resource } from '../../../router/AccessControlConfig';

type Props = {
  children: ReactNode;
  resource: Resource;
  action: Action;
  notAllowedComponent?: ReactNode;
};

export const AccessControl = ({
  resource,
  action,
  children,
  notAllowedComponent,
}: Props) => {
  const { isGranted } = useAccessControlContext();

  if (isGranted({ resource, action })) {
    return <>{children}</>;
  }

  return notAllowedComponent ? <>{notAllowedComponent}</> : null;
};
