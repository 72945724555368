import { rgba } from 'polished';
import styled from 'styled-components';

import { BUTTON_TEXT_CLASSNAME } from 'common/components/Button/styles';
import { theme } from 'common/theme';
import { themeColors } from 'common/theme/constants';
import { marginR, color, fontWeight } from 'common/utils/styleHelpers';

type Props = {
  navFilter?: boolean;
};

export const Navigation = styled.div(
  ({ navFilter }: Props) => `
      button{
        min-width: 120px;
      }
      button:not(:last-child){
        ${marginR(10)}
      }
      .active{
        background: ${
          navFilter
            ? themeColors.secondaryBackground
            : themeColors.primaryBackgound
        };
        .${BUTTON_TEXT_CLASSNAME} {
          ${
            navFilter
              ? color(themeColors.primaryText)
              : color(themeColors.secondaryText)
          };
        }
      }
      .not-active{
        background: ${
          navFilter ? rgba(theme.color.white, 0.1) : rgba(theme.color.dark, 0.1)
        };
        border: none;
        .${BUTTON_TEXT_CLASSNAME} {
          ${fontWeight('normal')}
          ${navFilter ? color(themeColors.secondaryText) : color('emperor')};
        }
      }
    `,
);
