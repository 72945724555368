import React from 'react';
import { Redirect } from 'react-router-dom';

import { Me } from 'authContext';
import { Routes } from 'router/routes';
import { getRoles } from 'utils';

interface Props {
  user: Me;
}

export const AuthenticatedUserRedirect = ({ user }: Props) => {
  const parsedRoles = getRoles(user?.roles);

  if (parsedRoles.admin || parsedRoles.customerSupport) {
    return <Redirect to={Routes.ORDERS.path} />;
  }
  if (parsedRoles.restaurantEmployee || parsedRoles.craveEmployee) {
    return <Redirect to={Routes.KDS.path} />;
  }

  // otherwise fallback should be kitchen page
  return <Redirect to={Routes.KITCHENS.path} />;
};
