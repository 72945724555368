import React, { useEffect, useMemo, useState } from 'react';

import { useReportContext } from '../../../manager/containers/Reports/hooks/reportContext';

import { formatRange } from '../../../utils/formatters/formatDate';
import {
  Table,
  ColumnWithId,
  StandardizedTableProps,
  TableData,
} from '../Table';

import { Row } from './index';

// TODO CRAVE-1609 style
export function StandardizedReportLayout<Values extends TableData = TableData>({
  exportData,
  reportName,
  columns,
  data,
  columnsWidth,
  exportLoading,
  ...rest
}: StandardizedTableProps<Values>) {
  const {
    timeFilter: { startDate, endDate },
    hiddenIds,
    setReportsObject,
  } = useReportContext();

  const [hiddenIndices, setHiddenIndices] = useState<number[]>([]);

  const columnsWithForcedId: ColumnWithId[] = useMemo(
    () => columns.map((c) => ({ ...c, id: c.id || c.Header })),
    [columns],
  );

  useEffect(() => {
    setHiddenIndices(
      hiddenIds.map((id) => columnsWithForcedId.findIndex((c) => c.id === id)),
    );
  }, [hiddenIds, columnsWithForcedId]);

  const columnWidthFiltered = useMemo(
    () =>
      columnsWidth
        .split(' ')
        .filter((_, index) => !hiddenIndices.includes(index))
        .join(' '),
    [columnsWidth, hiddenIndices],
  );

  const reportPeriod = formatRange(startDate, endDate);

  useEffect(() => {
    setReportsObject({
      fileName: `${reportName} - ${reportPeriod}`,
      exportData: exportData as (data: TableData[]) => any[][],
      data,
      hiddenColumns: hiddenIndices,
      exportLoading,
      columns,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [exportLoading]);

  return (
    <Row isScrollBarVisible overflowX="auto">
      <Table
        {...rest}
        data={data}
        columns={columnsWithForcedId}
        hiddenColumns={hiddenIds}
        columnsWidth={columnWidthFiltered}
      />
    </Row>
  );
}
