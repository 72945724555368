import styled from 'styled-components';

import { radius } from 'common/utils/styleHelpers';

import { Row, Col } from '../Layout';

export const Container = styled(Row).attrs({
  marginB: 'lg',
})``;

export const InputWrapper = styled(Col)`
  cursor: pointer;
`;

export const UploadIcon = styled.img`
  width: 32px;
  height: 32px;
`;

export const HiddenInput = styled.input`
  display: none;
`;

export const SelectedImage = styled.img(
  () => `
  width: 32px;
  height: 32px;
  ${radius('half')}
`,
);
