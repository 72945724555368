import React, { createContext, useCallback, useContext } from 'react';

import { useAuthContext } from '../../authContext';
import { isGranted } from 'router/AccessControlConfig/config';

import { Action, Resource } from '../../router/AccessControlConfig';
import { RuleFactory } from '../../router/AccessControlConfig/RuleFactory';

export type IsGrantedArgs = {
  action: Action;
  resource: Resource;
};

export type AccessControlContextType = {
  isGranted: ({ action, resource }: IsGrantedArgs) => boolean;
};

type ProviderProps = {
  children: React.ReactNode;
};

export const AccessControlProvider = ({ children }: ProviderProps) => {
  const { selectedFacility } = useAuthContext();

  const isGrantedFn = useCallback(
    (args: IsGrantedArgs) => {
      const rules = RuleFactory.create(
        selectedFacility?.readonly ? 'smallBizStack' : 'fullStack',
      );

      return isGranted(args, rules);
    },
    [selectedFacility],
  );

  return (
    <AccessControlContext.Provider value={{ isGranted: isGrantedFn }}>
      {children}
    </AccessControlContext.Provider>
  );
};

const AccessControlContext = createContext<
  AccessControlContextType | undefined
>(undefined);

export const useAccessControlContext = () => {
  const context = useContext(AccessControlContext);
  if (context === undefined) {
    throw new Error(
      'useAccessControlContext must be used within a AccessControlProvider',
    );
  }

  return context;
};
