module.exports = {
  name: 'Basic Theme',
  breakpoints: ['1336px', '1440px', '1920px', '2560px'],
  space: [],
  fontSize: {
    numbers: '46px',
    h1: '36px',
    h2: '28px',
    h3: '24px',
    h4: '16px',
    bodyHeader: '18px',
    body: '14px',
    small: '12px',
    graph: '10px',
    smallGraph: '8px',

    xxs: '11px',
    xs: '12px',
    sm: '14px',
    md: '16px',
    lg: '20px',
    xl: '22px',
    xxl: '30px',
    xxxl: '64px',
  },
  spacing: {
    none: '0',
    xxs: '4px',
    xs: '8px',
    sm: '12px',
    md: '16px',
    'md-lg': '20px',
    lg: '24px',
    xl: '32px',
    auto: 'auto',
  },
  color: {
    white: '#FFFFFF',
    whiteBg: '#F3F5F4',
    smokeWhite: '#F4F4F4',
    black: '#242424',
    blueDenim: '#608FE9',
    green: '#A1CA46',
    greenBright: '#3DC352',
    greenBg: 'rgba(161, 202, 70, 0.1)',
    greenDark: '#00CB00',
    greenLight: '#54C345',
    greenForest: '#249545',
    yellow: '#F3BC4C',
    yellowBg: 'rgba(243, 188, 76, 0.1)',
    grey: '#B5B5B5',
    gray: '#F4F4F5',
    grayLight: '#EDEDED',
    grayDark: '#B6C0CC',
    charcoalGrey: '#3A3A3A',
    greyDarkHard: '#A0A0A0',
    greyText: 'rgba(48, 30, 53, 0.4)',
    grayDarkBg: 'rgba(182, 192, 204, 0.1)',
    grayBorder: '#EAE8EA',
    smokeGray: '#978E9A',
    purple: '#301E35',
    purpleLight: '#615571',
    red: '#CC2E2E',
    redBg: 'rgba(204, 46, 46, 0.1)',
    redLight: '#FF6767',
    redDark: '#CA2929',
    transparent: 'transparent',
    twitter: '#00A1FA',
    facebook: '#34589D',
    seo: '#FFBA00',
    directLink: '#00C500',

    // New Colors
    eggPlant: '#504363',
    darkEggPlant: '#352849',
    mutedPurple: '#7C7389',
    emperor: '#535353',
    primaryYellow: '#E9AD60',
    lightYellow: '#FEC738',
    primaryGreen: '#61B98F',
    primaryRed: '#CE5454',
    blue: '#0080FF',
    dark: '#161616',
    beige: '#F9F6F1',
    deepBeige: '#ECE1D4',
    smokeYellow: '#FFF7EE',
    greenDarker: '#2ABB1E',
    whiteRock: '#EDE5D7',
    mischka: '#EAE9EB',
    silver: '#CCCCCC',
    steelWool: '#777777',
    smokeText: '#838383',
    cornflowerBlue: '#608FE9',
    evening: '#1C2129',
    carrot: '#E5804F',
    green2: '#46856F',
    orangeBg: '#FAEBD0',
    orange: '#EA8A52',
    oceanBlue: '#2D87B9',
    blueBg: '#DAE3F4',
    blueNavy: '#214A87',
    lightGrey: '#DCDCDC',
    seaGreen: '#CEE3E8',
    deepGreen: '#006262',
    springGreen: 'rgba(141, 172, 108, 0.15)',
    springGreen2: '#DCE9CE',
    treeGreen: '#8DAC6C',
    treeGreen2: '#366208',
    orangeCream: '#FFD6AF',
    burntOrange: '#E17550',
    orangeSwirl: 'rgba(225, 117, 80, 0.15)',
    blueSky: 'rgba(96, 143, 233, 0.15)',
    businessGray: '#F0F0F0',
    brown: '#846C62',
    lightBrown: 'rgba(132, 108, 98, 0.15)',
    seafoam: '#579195',
    lightSeafoam: 'rgba(87, 145, 149, 0.15)',
  },
  fontFamily: {
    primary: 'benton-sans',
  },
  fontWeight: {
    thin: '300',
    normal: '400',
    semiBold: '600',
    bold: '700',
  },
  lineHeight: {
    small: '1',
    normal: '1.25',
    large: '1.5',
    xLarge: '1.75',
    xxLarge: '2',
    initial: 'initial',
  },
  iconButtonSizes: {
    small: '24px',
    normal: '32px',
    medium: '40px',
    large: '48px',
  },
  iconSizes: {
    small: '14px',
    normal: '18px',
    medium: '20px',
    large: '24px',
  },
  borderRadius: {
    none: '0',
    xSmall: '6px',
    small: '10px',
    normal: '12px',
    medium: '16px',
    large: '20px',
    xl: '25px',
    xxl: '30px',
    xxxxl: '50px',
    half: '50%',
  },
  letterSpacings: {},
  sizes: [],
  borders: [
    '1px solid rgba(48, 30, 53, 0.1)',
    '1px solid rgba(0, 0, 0, 0.06)',
    '2px solid #0080FF',
    '1px solid #DDE4EB',
    '2px solid #F4F4F5',
    '2px solid #FEA026',
    '2px dotted #CC2E2E',
    '2px solid #EAE8EA',
  ],
  borderWidths: [],
  borderStyles: {},
  shadows: {
    card: '0px 0px 16px rgba(135, 135, 135, 0.08)',
    smallCard: '0px -1px 16px rgba(135, 135, 135, 0.08)',
    inlineCard: '0px 4px 10px rgba(145, 145, 145, 0.08)',
    popover: '0px 0px 10px rgba(84, 84, 84, 0.09)',
    border: '0px 0px 0px 2px black',
  },
  modalSize: {
    alert: 340,
    normal: 440,
    large: 600,
    small: 200,
  },
  /**
   * Z-Indices higher than 10
   * needs to put here
   * lower than 10 values used inside
   * component elements.
   * Modal Should be in front all
   * Shadow Should be behind modal
   * Navbar is the 3rd on top
   * Arragement should be modal,shadow,navbar, ... others
   */
  zIndices: {
    dropdown: 15,
    modal: 15,
    shadow: 14,
    navbar: 10,
    common: 5,
  },
  transitions: {},
};
