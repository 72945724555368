import React, { useState } from 'react';
import { useHistory } from 'react-router';
import { SortableHandle } from 'react-sortable-hoc';

import {
  KitchenMenuItemStatus,
  useDeleteKitchenMenuItemMutation,
  MenuItemType,
  InventoryStatus,
} from 'codegen/generated/graphql';
import { Badge } from 'common/components/Badge';
import { Icon } from 'common/components/Icon';
import { IconName } from 'common/components/Icon/types';
import { Col, Grid, Row } from 'common/components/Layout';
import { Text } from 'common/components/Text';
import { themeColors } from 'common/theme/constants';
import { ActionMenu } from 'manager/components/ActionMenu';
import { ConfirmModal } from 'manager/components/ConfirmModal';
import { ListItem } from 'manager/components/ListItem';
import { MultiBadge } from 'manager/components/MultiBadge';
import {
  SortIcon,
  DragButton,
  BrandLogo,
  Image,
  ImagePreview,
  Wrapper,
  ListItemWrapper,
  RedCloseCircleIcon,
  GreenCheckCircleIcon,
} from 'manager/containers/RestaurantAdministration/Restaurants/MenuList/styles';
import { ItemAvailabilityTags } from 'manager/utils/constants';
import { Routes } from 'router/routes';
import { LogEvent } from 'utils/analytics';
import { getRoute } from 'utils/getRoute';

import { ActiveStatus } from './ActiveStatus';
import { InventoryCount } from './InventoryCount';
import { PhysicalInventory } from './PhysicalInventory';
import { StatusLabel } from '../helpers';
import { ListItemProps } from '../types';

export const MenuItem = ({
  readOnly,
  item,
  showCategory,
  kitchenId,
  sortable,
  isCustomerSupport,
}: ListItemProps) => {
  const {
    name,
    id,
    price,
    menuCategories,
    kitchenLogo,
    inventoryCount: inventoryCountQL,
    status,
    enableInventoryCount,
    inventoryStatus,
    inventoryPreSale,
    availability,
    type,
    mealPackItems,
  } = item;

  const { push, location } = useHistory();
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const [deleteKitchenMenuItem, { loading }] =
    useDeleteKitchenMenuItemMutation();

  const mealPack = item.type === 'MEAL_PACK';
  const fromCraveMenu = location.pathname.includes('menu-order');

  const deleteProduct = async () => {
    try {
      await deleteKitchenMenuItem({
        variables: {
          id,
        },
      });
      await LogEvent('MENU_MANAGEMENT', {
        message: 'deleted',
        id,
      });
    } catch {
      //
    }
  };
  const menuActions = [
    {
      iconName: 'edit' as IconName,
      label: 'Edit',
      onClick: async () => {
        if (kitchenId && !mealPack) {
          const actualKitchenId = fromCraveMenu
            ? item.kitchenId || kitchenId
            : kitchenId;
          push(getRoute(Routes.MENU_EDIT.path, actualKitchenId, id));
        } else if (kitchenId && mealPack) {
          push(getRoute(Routes.EDIT_MEAL_PACK.path, kitchenId, id));
        }
        await LogEvent('MENU_MANAGEMENT', {
          message: `menu list: clicked "edit" action item"`,
          id,
        });
      },
    },
    {
      iconName: 'delete' as IconName,
      label: 'Delete',
      onClick: async () => {
        setShowDeleteModal(true);
        await LogEvent('MENU_MANAGEMENT', {
          message: `menu list: clicked "delete" action item`,
          id,
        });
      },
    },
  ];

  const inactiveMealPackItems = (mealPackItems ?? []).filter(
    (mealPackItem) => mealPackItem.status !== KitchenMenuItemStatus.Active,
  );

  const soldOutMealPackItems = (mealPackItems ?? []).filter(
    (mealPackItem) => mealPackItem.inventoryStatus === InventoryStatus.SoldOut,
  );

  const DragHandle = SortableHandle(() => (
    <DragButton>
      <SortIcon />
    </DragButton>
  ));
  const activeItem = status === KitchenMenuItemStatus.Active;

  return (
    <>
      <ListItemWrapper>
        <ListItem size="custom">
          <Col flex="1 0 100%" flexWrap="wrap" justifyContent="space-between">
            <Col alignItems="center" paddingY={15}>
              {sortable && (
                <Col marginR="sm">
                  <DragHandle />
                </Col>
              )}
              {item.imgUrl ? (
                <Wrapper>
                  <Image src={item.imgUrl} />
                  <ImagePreview src={item.imgUrl} />
                </Wrapper>
              ) : (
                <Col borderRadius="half" padding={5} background="smokeWhite">
                  <Icon url="/svg/fork_knive_icon.svg" size={16} />
                </Col>
              )}
              <Grid gridColumns="">
                <Col
                  alignItems="center"
                  marginB={3}
                  marginL="sm"
                  style={{ wordBreak: 'break-word' }}
                >
                  <Col onClick={menuActions[0].onClick} cursorPointer>
                    <Text fontSize="md" fontWeight="semiBold">
                      {name}
                    </Text>
                  </Col>
                  <Col marginL="sm">
                    <Badge noWrap text={`$${price.toFixed(2) ?? 0}`} />
                  </Col>
                </Col>
                <Row alignItems="center">
                  {availability && (
                    <>
                      <Text marginLeft="sm">
                        {availability.map(
                          (item, index, arr) =>
                            `${ItemAvailabilityTags[item]} ${
                              index !== arr.length - 1 ? ' | ' : ''
                            }`,
                        )}
                      </Text>
                      {inactiveMealPackItems.length > 0 && (
                        <>
                          <Text
                            fontWeight="bold"
                            fontSize="xs"
                            color={themeColors.primaryRed}
                          >
                            &nbsp;
                            {`- ${inactiveMealPackItems.length} inactive items`}
                          </Text>
                          <ActionMenu
                            inline
                            isInfo
                            actions={inactiveMealPackItems.map((i) => ({
                              label: i.name,
                            }))}
                          />
                        </>
                      )}
                      {soldOutMealPackItems.length > 0 && (
                        <>
                          <Text
                            fontWeight="bold"
                            fontSize="xs"
                            color={themeColors.primaryRed}
                          >
                            &nbsp;
                            {`- ${soldOutMealPackItems.length} sold out items`}
                          </Text>
                          <ActionMenu
                            inline
                            isInfo
                            actions={soldOutMealPackItems.map((i) => ({
                              label: i.name,
                            }))}
                          />
                        </>
                      )}
                    </>
                  )}
                </Row>
              </Grid>
            </Col>
            <Col paddingY={15} paddingX={5}>
              {showCategory && (
                <MultiBadge
                  values={menuCategories.map((category) => category.name)}
                />
              )}
            </Col>
            <Col paddingY={15}>
              <Col alignItems="center">
                {type === MenuItemType.MealPack ? (
                  <Row>
                    {enableInventoryCount && inventoryCountQL ? (
                      <>
                        <GreenCheckCircleIcon />
                        <Text marginLeft="sm">
                          {StatusLabel[inventoryStatus]}
                        </Text>
                      </>
                    ) : (
                      <>
                        <RedCloseCircleIcon />
                        <Text marginLeft="sm">Sold Out</Text>
                      </>
                    )}
                    <Text
                      fontSize="sm"
                      color="emperor"
                      marginLeft={3}
                      marginRight="sm"
                    >
                      {`(${inventoryCountQL})`}
                    </Text>
                  </Row>
                ) : (
                  activeItem && (
                    <InventoryCount
                      id={id}
                      inventoryStatus={inventoryStatus}
                      enableInventory={enableInventoryCount}
                      inventoryCountQL={inventoryCountQL || null}
                      isCustomerSupport={isCustomerSupport}
                      isActive={activeItem}
                    />
                  )
                )}
              </Col>
              <Col>
                {activeItem && (
                  <PhysicalInventory inventoryPreSale={inventoryPreSale} />
                )}
              </Col>
              <Col alignItems="center">
                <ActiveStatus
                  id={id}
                  status={status}
                  disabled={!!isCustomerSupport}
                />
              </Col>
              <Col alignItems="center" justifyContent="flex-end">
                {readOnly ? (
                  <BrandLogo
                    src={kitchenLogo || '/svg/crave-kitchen-placeholder.svg'}
                    alt="restaurant-logo"
                  />
                ) : (
                  <ActionMenu inline actions={menuActions} />
                )}
              </Col>
            </Col>
          </Col>
        </ListItem>
      </ListItemWrapper>
      <ConfirmModal
        loading={loading}
        visible={showDeleteModal}
        message={`Are you sure to delete ${name ?? 'this product'}?`}
        onCancel={() => setShowDeleteModal(false)}
        onConfirm={async () => {
          await deleteProduct();
          setShowDeleteModal(false);
        }}
      />
    </>
  );
};
