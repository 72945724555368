import styled from 'styled-components';

import {
  display,
  background,
  radius,
  alignItems,
  justifyContent,
} from 'common/utils/styleHelpers';

export const Count = styled.div<{ size: number }>(
  ({ size }) => `
  ${display('flex')}
  ${background('black')}
  ${radius('half')}
  ${alignItems('center')}
  ${justifyContent('center')}
  width: ${size}px;
  height: ${size}px;
  `,
);
