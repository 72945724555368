import React from 'react';
import { Row } from 'react-table';

import { RowProps, RowsProps } from '../types';

import * as S from '../styles';

export const TableRow = ({ row }: { row: Row<any> }) => (
  <>
    {row.cells.map((cell, index) => {
      const isPlainCell = Boolean(
        !cell.isGrouped && !cell.isAggregated && !cell.isPlaceholder,
      );

      return (
        <S.Cell {...cell.getCellProps()} key={`${row.id}-cell-${index}`}>
          {Boolean(cell.isGrouped) && (
            // If it's a grouped cell, add an expander and row count
            <>
              <span {...row.getToggleRowExpandedProps()}>
                {row.isExpanded ? '👇' : '👉'}
              </span>{' '}
              {cell.render('Cell')} ({row.subRows.length})
            </>
          )}
          {Boolean(cell.isAggregated) &&
            // If the cell is aggregated, use the Aggregated
            // renderer for cell
            cell.render('Aggregated')}
          {isPlainCell && // For cells with repeated values, render null
            // Otherwise, just render the regular cell
            cell.render('Cell')}
        </S.Cell>
      );
    })}
  </>
);

export const Rows = ({ rows, prepareRow, ...props }: RowsProps & RowProps) => {
  return (
    <>
      {rows.map((row) => {
        prepareRow(row);

        return (
          <S.TableRow className="table-row" key={row.id} {...props}>
            <TableRow row={row} />
          </S.TableRow>
        );
      })}
    </>
  );
};
