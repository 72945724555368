import { Color } from 'styled-components';

// Constants that point to key in theme so we can use type, theme, and style helpers
// without passing just a string
export const themeColors = {
  white: 'white' as keyof Color,
  dark: 'dark' as keyof Color,
  primaryText: 'dark' as keyof Color,
  secondaryText: 'white' as keyof Color,
  primaryBackgound: 'black' as keyof Color,
  secondaryBackground: 'white' as keyof Color,
  primaryGreen: 'primaryGreen' as keyof Color,
  primaryRed: 'primaryRed' as keyof Color,
  black: 'black' as keyof Color,
};
