import { UserRole } from 'codegen/generated/graphql';
import {
  FacilityManagementAccessors,
  RestaurantManagementAccessors,
  BasicViewsAccessors,
  FacilityEmployeeAccessors,
} from 'utils';

export const BASE_URL = {
  Analytics: '/manager/analytics',
  Content: '/manager/content',
  Customers: '/manager/customers',
  DriverManagement: '/manager/driver-management',
  Kitchen: '/manager/brand',
  MenuPlanner: '/manager/menu-planner',
  MenuPlannerSpecials: '/manager/menu-planner-specials',
  FacilitySettings: '/manager/facilities-settings',
  BrandsReordering: '/manager/facilities-settings/brands-reordering',
  FacilitySettingsProfile: '/manager/facilities-settings/profile',
  FacilitySettingsOverrides:
    '/manager/facilities-settings/order-methods-overrides',
  FacilitySettingsOperationHours:
    '/manager/facilities-settings/facilities-operation-hours',
  FacilitySettingsTaxCalculations:
    '/manager/facilities-settings/tax-calculations',
  FacilitySettingsPlatforms: '/manager/facilities-settings/platforms',
  Orders: '/manager/orders',
  Promos: '/manager/promos',
  QRCode: '/manager/qrcode',
  ToKds: '/manager/to-kds',
  UserManagement: '/manager/user-management',
  DeveloperTools: '/developer/tools',
};

// If you don't want to get redirected when switching facilities
// you should add that route here,
// non redirect routes as well routes that are on first level of sidebar menu
export const NON_REDIRECT_URLS = [
  BASE_URL.BrandsReordering,
  BASE_URL.FacilitySettingsProfile,
  BASE_URL.FacilitySettingsOperationHours,
  BASE_URL.FacilitySettingsOverrides,
  BASE_URL.FacilitySettingsTaxCalculations,
  BASE_URL.MenuPlannerSpecials,
];

const CorporateAdminAccessors = [UserRole.CorporateAdmin];

export const Routes = {
  /* SIGN IN FLOW */
  FORGOT_PASSWORD: {
    path: '/forgot-password',
  },
  LOGIN: {
    path: '/login',
  },
  RESET_PASSWORD: {
    path: '/reset-password',
  },

  ADD_PROMO: {
    path: `${BASE_URL.Promos}/add`,
    allowedRoles: FacilityEmployeeAccessors,
  },
  ADD_MEAL_PACK: {
    path: `${BASE_URL.Kitchen}/:kitchenId/add`,
    allowedRoles: RestaurantManagementAccessors,
  },
  ADD_SINGLE_MP: {
    path: `${BASE_URL.Kitchen}/:kitchenId/menu/meal-packs/add`,
    allowedRoles: FacilityManagementAccessors,
  },
  ANALYTICS: {
    path: BASE_URL.Analytics,
    allowedRoles: RestaurantManagementAccessors,
  },
  ANALYTICS_ADD: {
    path: `${BASE_URL.Analytics}/add`,
    allowedRoles: FacilityManagementAccessors,
  },
  ANALYTICS_EDIT: {
    path: `${BASE_URL.Analytics}/edit`,
    allowedRoles: FacilityManagementAccessors,
  },
  CONTENT_MARKETING: {
    path: `${BASE_URL.Content}/marketing`,
    allowedRoles: FacilityManagementAccessors,
  },
  CONTENT_MARKETING_EDIT: {
    path: `${BASE_URL.Content}/marketing/edit`,
    allowedRoles: FacilityManagementAccessors,
  },
  CONTENT_EMBEDDED_REPORTS: {
    path: `${BASE_URL.Content}/embedded-reports`,
    allowedRoles: FacilityManagementAccessors,
  },
  CUSTOMERS: {
    path: BASE_URL.Customers,
    allowedRoles: RestaurantManagementAccessors,
  },
  CUSTOMER_PROFILE: {
    path: `${BASE_URL.Customers}/:id/profile`,
    allowedRoles: RestaurantManagementAccessors,
  },
  CUSTOMER_ADDRESSES: {
    path: `${BASE_URL.Customers}/:id/addresses`,
    allowedRoles: RestaurantManagementAccessors,
  },
  CUSTOMER_ORDERS: {
    path: `${BASE_URL.Customers}/:id/orders`,
    allowedRoles: RestaurantManagementAccessors,
  },
  DRIVER_MANAGEMENT: {
    path: `${BASE_URL.DriverManagement}`,
    allowedRoles: FacilityEmployeeAccessors,
  },
  EDIT_MEAL_PACK: {
    path: `${BASE_URL.Kitchen}/:kitchenId/edit/:itemId`,
    allowedRoles: RestaurantManagementAccessors,
  },
  EDIT_SINGLE_MP: {
    path: `${BASE_URL.Kitchen}/:kitchenId/menu/meal-packs/edit/:itemId`,
    allowedRoles: RestaurantManagementAccessors,
  },
  KDS: {
    path: BASE_URL.ToKds,
  },

  /* KITCHEN */
  KITCHEN: {
    path: `${BASE_URL.Kitchen}/:kitchenId/profile`,
    allowedRoles: RestaurantManagementAccessors,
  },
  KITCHENS: {
    // this will updated as a bug fix to fix routing
    path: `${BASE_URL.Kitchen}s`,
    allowedRoles: BasicViewsAccessors,
  },
  KITCHEN_ADD: {
    path: `${BASE_URL.Kitchen}s/add`,
    allowedRoles: FacilityManagementAccessors,
  },
  KITCHEN_OPERATION_HOURS: {
    path: `${BASE_URL.Kitchen}/:kitchenId/operation-hours`,
    allowedRoles: RestaurantManagementAccessors,
  },
  MEAL_PACKS: {
    path: `${BASE_URL.Kitchen}s/meal-packs`,
    allowedRoles: FacilityManagementAccessors,
  },
  MODIFIERS: {
    path: `${BASE_URL.Kitchen}/:kitchenId/modifiers`,
    allowedRoles: RestaurantManagementAccessors,
  },
  CATEGORIES: {
    path: `${BASE_URL.Kitchen}s/categories`,
    allowedRoles: FacilityEmployeeAccessors,
  },

  /* MENU_PLANNER */
  MENU_PLANNER_ALL: {
    path: BASE_URL.MenuPlanner,
    allowedRoles: FacilityManagementAccessors,
  },
  MENU_PLANNER_SINGLE_KITCHEN: {
    path: `${BASE_URL.MenuPlanner}/:kitchenId`,
    allowedRoles: RestaurantManagementAccessors,
  },
  MENU_PLANNER_ADD: {
    path: `${BASE_URL.MenuPlanner}/:kitchenId/add`,
    allowedRoles: BasicViewsAccessors,
  },
  MENU_PLANNER_EDIT: {
    path: `${BASE_URL.MenuPlanner}/:kitchenId/edit/:menuScheduleId`,
    allowedRoles: BasicViewsAccessors,
  },
  MENU_PLANNER_SPECIAL_CREATE: {
    path: `${BASE_URL.MenuPlannerSpecials}/create`,
    allowedRoles: FacilityEmployeeAccessors,
  },
  MENU_PLANNER_SPECIAL_EDIT: {
    path: `${BASE_URL.MenuPlannerSpecials}/edit/:menuScheduleId`,
    allowedRoles: FacilityEmployeeAccessors,
  },
  MENU_PLANNER_SPECIALS: {
    path: `${BASE_URL.MenuPlannerSpecials}`,
    allowedRoles: FacilityEmployeeAccessors,
  },

  /* FACILITY SETTINGS */
  ORDER_METHODS_OVERRIDES: {
    path: `${BASE_URL.FacilitySettings}/order-methods-overrides`,
    allowedRoles: FacilityManagementAccessors,
  },
  ORDER_METHOD_OVERRIDE_ADD: {
    path: `${BASE_URL.FacilitySettings}/override/add`,
    allowedRoles: FacilityEmployeeAccessors,
  },
  ORDER_METHOD_OVERRIDE_EDIT: {
    path: `${BASE_URL.FacilitySettings}/override/edit/:orderMethodId`,
    allowedRoles: FacilityEmployeeAccessors,
  },
  BRANDS_REORDERING: {
    path: BASE_URL.BrandsReordering,
    allowedRoles: FacilityManagementAccessors,
  },
  FACILITY_OPERATION_HOURS: {
    path: `${BASE_URL.FacilitySettingsOperationHours}`,
    allowedRoles: FacilityManagementAccessors,
  },
  FACILITY_PROFILE: {
    path: `${BASE_URL.FacilitySettingsProfile}`,
    allowedRoles: FacilityManagementAccessors,
  },
  FACILITY_TAX_CALCULATIONS: {
    path: BASE_URL.FacilitySettingsTaxCalculations,
    allowedRoles: FacilityManagementAccessors,
  },
  FACILITY_PLATFORMS: {
    path: `${BASE_URL.FacilitySettingsPlatforms}`,
    allowedRoles: FacilityEmployeeAccessors,
  },

  MENU: {
    path: `${BASE_URL.Kitchen}/:kitchenId/menu`,
    allowedRoles: BasicViewsAccessors,
  },
  MENU_ADD: {
    path: `${BASE_URL.Kitchen}/:kitchenId/menu/add/:type`,
    allowedRoles: RestaurantManagementAccessors,
  },
  MENU_EDIT: {
    path: `${BASE_URL.Kitchen}/:kitchenId/menu/edit/:itemId`,
    allowedRoles: RestaurantManagementAccessors,
  },
  MENU_ORDER: {
    path: `${BASE_URL.Kitchen}/menu-order`,
    allowedRoles: FacilityEmployeeAccessors,
  },
  ORDERS: {
    path: BASE_URL.Orders,
    allowedRoles: FacilityEmployeeAccessors,
  },
  PROMOS: {
    path: BASE_URL.Promos,
    allowedRoles: FacilityEmployeeAccessors,
  },
  QRCODE: {
    path: BASE_URL.QRCode,
  },
  RO_ACTIVE_ORDERS: {
    path: `${BASE_URL.Kitchen}/:kitchenId/active-orders`,
    allowedRoles: [UserRole.RestaurantOwner],
  },
  UPDATE_PROMO: {
    path: `${BASE_URL.Promos}/:promoId`,
    allowedRoles: FacilityEmployeeAccessors,
  },
  USER_MANAGEMENT: {
    path: `${BASE_URL.UserManagement}`,
    allowedRoles: FacilityEmployeeAccessors,
  },
  USER_DETAILS: {
    path: `${BASE_URL.UserManagement}/:userId`,
    allowedRoles: FacilityEmployeeAccessors,
  },
  DEVELOPER_TOOLS: {
    path: `${BASE_URL.DeveloperTools}`,
    allowedRoles: CorporateAdminAccessors,
  },
};
