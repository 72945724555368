import styled, { css } from 'styled-components';

import { theme } from 'common/theme';
import {
  color,
  radius,
  font,
  background as backgroundColor,
} from 'common/utils/styleHelpers';

export const StyledMultiSelectDiv = styled.div<{
  fluid?: boolean;
}>(
  ({ fluid }) => css`
    ${fluid ? 'width: 100%;' : ''}
    * {
      ${font('primary')}
      font-style: normal !important;
      font-weight: normal !important;
      font-size: 14px !important;
      line-height: 14px !important;
      color: ${theme.color.dark} !important;
    }

    > div {
      width: ${fluid ? '100%' : '50%'};
    }

    > div > div > div:first-child {
      border: none;
      ${backgroundColor('beige')}
      ${radius('xl')}
      min-height: 38px !important;
      padding: 8px 20px;
    }

    * .searchBox {
      border: none !important;
      min-width: 200px;

      ::placeholder {
        ${color('greyText')}
        line-height: initial;
      }
    }

    * .chip {
      background-color: #ffffff !important;
      border: 1px solid ${theme.color.deepBeige};
    }

    * .optionContainer {
      border: none !important;
      ${backgroundColor('white')}
      border: 1px solid ${theme.color.deepBeige}!important;
      box-sizing: border-box;
      border-radius: 12px;

      > li.highlight {
        background-color: ${theme.color.beige};
      }

      > li:not(.option) {
        color: ${theme.color.smokeText} !important;
      }
    }

    * .option {
      &:hover {
        background-color: #f9f6f1;
      }
    }
  `,
);
