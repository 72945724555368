import { Resource } from 'router/AccessControlConfig';
import { Routes } from 'router/routes';

export const RESTAURANT_ADMIN_NAV_OPTIONS_BY_GRANT: {
  label: string;
  value: string;
  resource: Resource;
}[] = [
  {
    label: 'Brands',
    value: Routes.KITCHENS.path,
    resource: Resource.BrandsList,
  },
  {
    label: 'All Menu Items',
    value: Routes.MENU_ORDER.path,
    resource: Resource.BrandsMenuItems,
  },
  // TODO: This is disabled for because it's not very utilized
  // {
  //   label: 'Multi Brand Meal Packs',
  //   value: Routes.MEAL_PACKS.path,
  //   resource: Resource.BrandsMultiBrandMealPack,
  // },
  {
    label: 'Categories',
    value: Routes.CATEGORIES.path,
    resource: Resource.BrandsCategories,
  },
];

export const SUPPORTED_FILE_TYPES = [
  'image/svg',
  'image/svg+xml',
  'image/png',
  'image/jpeg',
];
