import Loader from 'react-loader-spinner';

import styled from 'styled-components';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';

export const Spinner = styled(Loader)<{ fullScreen?: boolean }>(
  ({ fullScreen }) => `
    display: ${fullScreen ? 'block' : 'inline'};
    vertical-align: text-top;
    ${fullScreen && `text-align: center;`}
    ${fullScreen && `margin-top: 100px;`}
  `,
);
