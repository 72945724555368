import React from 'react';

import { themeColors } from 'common/theme/constants';

import { Icon, IconSize } from '../Icon';

import { BackButton } from './styles';

interface Props {
  onClick: () => void;
}
export const ButtonBack = ({ onClick }: Props) => {
  return (
    <BackButton onClick={onClick} aria-label="Back Button">
      <Icon
        name="chevron_left"
        iconColor={themeColors.primaryText}
        size={IconSize.NORMAL}
      />
    </BackButton>
  );
};
