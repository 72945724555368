import React from 'react';

import { isStaging, isProduction } from 'utils';

export interface FallbackComponentProps {
  error?: Error;
  info?: React.ErrorInfo;
}

export const AppError = ({ error, info }: FallbackComponentProps) => {
  if (isStaging() || isProduction()) {
    return (
      <div>
        <h3>An error has occurred, please refresh the browser window</h3>
      </div>
    );
  }

  return (
    <div>
      <b>{error?.message}</b>
      {info?.componentStack
        ?.split(/\r?\n/)
        .map((line: React.ReactNode, i: number) => (
          <p key={`trace-line-${i}`}>{line}</p>
        ))}
    </div>
  );
};
