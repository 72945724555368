import { useState, useEffect } from 'react';

import { dateInFacilityTz } from 'common/utils/time';

export const useClock = ({
  time = dateInFacilityTz(),
  interval,
}: {
  time?: Date;
  interval: number;
}) => {
  const [date, setDate] = useState(time);

  useEffect(() => {
    // prevent bugs with 0 interval
    if (interval === 0) return;

    const timerID = setInterval(
      () => setDate(dateInFacilityTz(new Date())),
      interval,
    );

    return () => clearInterval(timerID);
  }, [interval]);

  return date;
};
