import React, { FC, ReactNode, useEffect, useState } from 'react';

import { ThemeProvider } from 'styled-components';

import { Tenant } from 'codegen/generated/graphql';
import { Loader } from 'common/components';
import { logToGCP } from 'utils/logging';

import { GlobalFonts, GlobalStyles, theme } from './common/theme';
import {
  fetchTenant,
  getTenantFromStorage,
  setMetaDataFromTenant,
  setTenantInStorage,
} from './utils/tenant';

export const TenantGuard: FC<{
  children: (props: { tenant: Tenant }) => ReactNode;
}> = ({ children }) => {
  const [tenant, setTenant] = useState<Tenant | undefined>(
    getTenantFromStorage,
  );
  const [loading, setLoading] = useState(!tenant);

  const loadTenantId = async () => {
    setLoading(true);
    try {
      const tenant = await fetchTenant();

      setTenant(tenant);
      setTenantInStorage(tenant);
    } catch (e) {
      if (e instanceof Error) {
        logToGCP(`Error loading tenant: ${e.message}`);
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!tenant?.id) {
      loadTenantId();
    }
  }, [tenant?.id]);

  useEffect(() => {
    tenant && setMetaDataFromTenant(tenant);
  }, [tenant]);

  if (loading && !tenant) {
    return (
      <ThemeProvider theme={theme}>
        <GlobalFonts />
        <GlobalStyles />
        <Loader fullScreen />
      </ThemeProvider>
    );
  }

  if (tenant && !loading) {
    return <>{children({ tenant })}</>;
  } else {
    return <>Error - cannot get tenant id</>;
  }
};
