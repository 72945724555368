import { InventoryStatus } from 'codegen/generated/graphql';

type StatusType = {
  [key in InventoryStatus]: string;
};

export const StatusLabel: StatusType = {
  [InventoryStatus.InStock]: 'Available',
  [InventoryStatus.SoldOut]: 'Sold Out',
};
