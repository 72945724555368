import React from 'react';

import { KitchenUpdate } from 'manager/containers/RestaurantAdministration/Restaurants/KitchenUpdate';
import { ManagerLayout as Layout } from 'manager/layout';

import { Resource } from '../../router/AccessControlConfig';

// TODO: Kitchen should contain only kitchen related logic
// TODO: This logic should be abstracted into more generic place
export enum Type {
  Profile,
  Menu,
  Add,
  ActiveOrders,
  MealPacks,
  MenuPlanner,
  SpecialMenuPlanner,
  OrderMethodOverrideUpsert,
  Modifiers,
}

export interface Props {
  type?: Type; // TODO Is name of the props "type" reflecting what does it do?
}

const Kitchen = ({ type }: Props) => {
  return (
    <Layout resource={Resource.Brands}>
      <KitchenUpdate type={type} />
    </Layout>
  );
};

export default Kitchen;
