import { setDay, format, startOfWeek, endOfWeek, subDays } from 'date-fns';
import { utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';

export const TIME_FORMAT = 'h:mma';
export const SHORT_TIME_FORMAT = 'h:mm';

export const WEEK_DAY_INDEXES = [0, 1, 2, 3, 4, 5, 6];

/** According the USA date system and JS standard also starts with 0 as Sunday */
export const DAYS_OF_WEEK_BY_DAY_INDEX: { [index: number]: string } = {
  0: 'Sunday',
  1: 'Monday',
  2: 'Tuesday',
  3: 'Wednesday',
  4: 'Thursday',
  5: 'Friday',
  6: 'Saturday',
};

export const ABBREVIATED_DAY_INDEX_TO_NAME: { [key: string]: string } = {
  0: 'Sun',
  1: 'Mon',
  2: 'Tue',
  3: 'Wed',
  4: 'Thu',
  5: 'Fri',
  6: 'Sat',
};

export const ROUND_UP_ONE_HOUR = 1;
export const ROUND_UP_TWO_HOURS = 2;

export let FACILITY_TZ = 'America/Boise'; // default timezone
export const setFacilityTz = (tz: string) => {
  FACILITY_TZ = tz;
};

export const dateInFacilityTz = (dateInput: string | Date = new Date()) => {
  dateInput = dateInput ?? new Date();
  const date = typeof dateInput === 'string' ? new Date(dateInput) : dateInput;

  return utcToZonedTime(date, FACILITY_TZ);
};

export const getTime = (date: Date) => format(date, 'HH:mm');

export const getDateTime = (date: Date) => format(date, 'yyyy-MM-dd');

export const facilityTzInUtc = (date: Date) => {
  return zonedTimeToUtc(date, FACILITY_TZ);
};

export const localizedToday = dateInFacilityTz();
export const localizedYesterday = subDays(localizedToday, 1);
export const localizedWeekAgo = subDays(localizedToday, 7);

/** Takes hours (0 - 23) and minutes (0 - 59) and transfer them into minutes only */
export const getTimeInMinutes = (hours: number, minutes: number) =>
  hours * 60 + minutes;

export const getYesterday = (date: Date, dayInWeek: number) =>
  setDay(date, dayInWeek - 1);

export const calendarDateDisplayByDay = (date: Date) => {
  return format(date, 'MMMM d, yyyy');
};

export const calendarDateDisplayByWeek = (date: Date) => {
  const startDate = format(startOfWeek(date), 'MMM d');
  const endDate = format(endOfWeek(date), 'MMM d');

  return `${startDate} - ${endDate}`;
};

export const calendarDateDisplayByMonth = (date: Date) => {
  return format(date, 'MMMM yyyy');
};

export const getDefaultStartTime = (date: Date) => {
  date.setHours(date.getHours() + ROUND_UP_ONE_HOUR);
  date.setMinutes(0, 0, 0);

  return date;
};

export const getDefaultEndTime = (date: Date) => {
  date.setHours(date.getHours() + ROUND_UP_TWO_HOURS);
  date.setMinutes(0, 0, 0);

  return date;
};

export const wait = (ms: number) =>
  new Promise((resolve) => setTimeout(resolve, ms));
