import styled from 'styled-components';

import { themeColors } from 'common/theme/constants';
import {
  fontSize,
  color,
  lineHeight,
  paddingX,
  font,
  radius,
  background as backgroundColor,
  paddingL,
  paddingR,
  ColorProps,
  marginB,
  SpacingType,
} from 'common/utils/styleHelpers';

export type Props = {
  background?: ColorProps;
  fluid?: boolean;
  iconSrc?: string;
  iconPosition?: 'left' | 'right';
  marginBottom?: SpacingType;
} & React.InputHTMLAttributes<HTMLInputElement>;

export const Input = styled.input<Props>(
  ({ fluid, iconSrc, background, iconPosition, marginBottom }) => `
  ${iconSrc ? `background: url(${iconSrc}) no-repeat` : ''};
  ${iconSrc ? `background-size: 14px 14px` : ''};
  ${
    iconPosition === 'right'
      ? 'background-position: right 15px center'
      : 'background-position: 13px center'
  };
  ${backgroundColor(background ?? 'beige')}
  ${font('primary')}
  ${fontSize('body')}
  ${lineHeight('small')}
  ${color(themeColors.primaryText)}
  ${paddingX(20)}
  ${radius('xl')}
  ${marginBottom ? marginB(marginBottom) : ''}
  ::placeholder {
    ${color('greyText')}
    line-height: initial;
  }
  -webkit-appearance: none;
  ::-webkit-inner-spin-button,
  ::-webkit-outer-spin-button {
    -webkit-appearance: none;
  }
  outline: none;
  border: none;
  height: 40px;
  ${fluid ? 'width: 100%' : ''};

  ${iconSrc ? (iconPosition === 'right' ? paddingR(40) : paddingL(40)) : ''};

  &:disabled {
    opacity: 0.3;
  }
  `,
);
