import { Property as CsstypeProperty } from 'csstype';
import styled from 'styled-components';

import { Row, Col } from 'common/components/Layout';
import {
  display as displayType,
  position,
  justifyContent,
  alignItems,
  zIndex,
} from 'common/utils/styleHelpers';

interface Props {
  height?: string;
  display?: CsstypeProperty.Display;
}

export const Wrapper = styled(Row)(
  ({ height, display }: Props) => `
  ${display ? displayType(display) : ''}
  ${height ? `height: ${height};` : ''}
  overflow: hidden;
  width: 100%;
  position: relative;
  `,
);

export const Content = styled(Col).attrs({
  padding: 'lg',
  flex: 1,
  background: 'white',
})(
  () => `
  width: 100%;
  overflow: auto;
`,
);

export const LoadingWrapper = styled.div(
  () => `
  ${position('absolute')}
  ${displayType('flex')}
  ${justifyContent('center')}
  ${alignItems('center')}
  ${zIndex('common')}
  background: rgba(255, 255, 255, 0.8);
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
`,
);
