import React from 'react';

import { differenceInSeconds, format } from 'date-fns';

import { KdsKitchenTicketsQuery } from 'codegen/generated/graphql';
import { Row, Col } from 'common/components/Layout';
import { Text } from 'common/components/Text';
import { themeColors } from 'common/theme/constants';
import { Panel } from 'manager/components/Panel';

interface Props {
  ticket: KdsKitchenTicketsQuery['kdsKitchenTickets'][0];
}

export interface Item {
  [key: string]: {
    id: string;
    quantity: number;
    name: string;
  };
}

export const Item = ({ ticket }: Props) => {
  const {
    items,
    delayTime,
    refireReason,
    refiredFromId,
    preparingUntil,
    preparingFrom,
  } = ticket;

  const orderShortId = ticket.order.shortId;
  const preparingUntilInSecond = differenceInSeconds(
    new Date(preparingUntil),
    new Date(),
  );

  const background =
    preparingUntilInSecond <= 0 ? themeColors.primaryRed : 'deepBeige';
  const color =
    preparingUntilInSecond <= 0
      ? themeColors.secondaryText
      : themeColors.primaryText;

  return (
    <Panel
      radius="all"
      subheader={
        <Row
          background={background}
          padding="sm"
          justifyContent="space-between"
        >
          <Col>
            <Row direction="column">
              <Text fontWeight="bold" fontSize="md" color={color}>
                Ticket #{orderShortId ?? 'N/A'}
              </Text>
              <Text fontSize="sm" color={color}>
                {preparingFrom
                  ? format(new Date(preparingFrom), 'hh:mm a')
                  : 'Not started'}
              </Text>
            </Row>
          </Col>
          <Col>
            <Col alignItems="center" marginR="sm">
              <Text fontSize="md" color={color}>
                {format(new Date(preparingUntil), 'H:mm')}
              </Text>
              {delayTime && (
                <Text
                  marginLeft={3}
                  fontSize="md"
                  fontWeight="bold"
                  color={color}
                >
                  +{delayTime} min
                </Text>
              )}
            </Col>
          </Col>
        </Row>
      }
    >
      <Row>
        {items.map((item, i) => {
          const quantity = item.quantity;

          return (
            <Row
              key={item.id}
              alignItems="center"
              {...(items.length - 1 !== i ? { marginB: 'xs' } : {})}
            >
              <Col
                background={
                  quantity > 1
                    ? themeColors.primaryBackgound
                    : themeColors.secondaryBackground
                }
                height="fit-content"
                minWidth="fit-content"
                marginR="md"
                borderRadius="xSmall"
                padding={5}
              >
                <Text
                  fontSize="sm"
                  fontWeight={quantity > 1 ? 'bold' : 'normal'}
                  color={
                    quantity > 1
                      ? themeColors.secondaryText
                      : [themeColors.primaryText, 0.6]
                  }
                >
                  {quantity}x
                </Text>
              </Col>
              <Col>
                <Text>{item.orderItem.name}</Text>
              </Col>
            </Row>
          );
        })}
      </Row>
      {refiredFromId && (
        <Row direction="column" marginT="sm" paddingT="sm" borderTop={1}>
          <Text fontSize="xs" fontWeight="bold">
            Expeditor’s note
          </Text>
          <Row
            background={[themeColors.primaryRed, 0.05]}
            borderRadius="normal"
            paddingX="md"
            paddingY="xs"
            marginT="xs"
          >
            <Text color={themeColors.primaryRed}>{refireReason || 'N/A'}</Text>
          </Row>
        </Row>
      )}
    </Panel>
  );
};
