import React, { CSSProperties } from 'react';

import { StyledText, StyledTextProps } from './styles';

type Element = 'p' | 'span' | 'div' | 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';

interface Props extends StyledTextProps {
  id?: string;
  as?: Element;
  className?: string;
  style?: CSSProperties;
  children: React.ReactNode;
}

export const Text = ({
  children,
  as = 'p',
  fontFamily,
  fontSize,
  fontWeight,
  color,
  lineHeight,
  alignSelf,
  textAlign,
  textTransform,
  fontStyle,
  spaceAfter,
  marginRight,
  marginLeft,
  whitespace,
  truncateText,
  letterSpacing,
  className,
  displayFlex,
  strikeThrough,
  style,
  ...rest
}: Props) => (
  <StyledText
    className={className}
    as={as}
    fontFamily={fontFamily}
    fontSize={fontSize}
    fontWeight={fontWeight}
    // Reason of ts-ignore is color is a html font tag property since we are not using for this
    // it gives the error of type is not compatible which is changed for colors as string | [string,opacity]
    // instead changing property name and check colors on every page ts-ignore added
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    color={color}
    lineHeight={lineHeight}
    alignSelf={alignSelf}
    textAlign={textAlign}
    textTransform={textTransform}
    fontStyle={fontStyle}
    spaceAfter={spaceAfter}
    marginRight={marginRight}
    marginLeft={marginLeft}
    whitespace={whitespace}
    truncateText={truncateText}
    aria-label={rest['aria-label']}
    letterSpacing={letterSpacing}
    displayFlex={displayFlex}
    strikeThrough={strikeThrough}
    style={style}
    {...rest}
  >
    {children}
  </StyledText>
);
