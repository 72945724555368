import { Property as CsstypeProperty } from 'csstype';
import { transparentize } from 'polished';
import {
  Color,
  Spacing,
  BorderRadius,
  Shadows,
  FontFamily,
  FontSizes,
  IconSizes,
  FontWeight,
  LineHeight,
  ZIndices,
  ModalSize,
} from 'styled-components';

import { theme } from '../theme';

/** Beginning of Display Helpers */
export const display = (display: CsstypeProperty.Display) => `
  display: ${display};
`;

export type FlexProps = 'row' | 'column' | 'row-reverse' | 'column-reverse';
export const flex = (direction: FlexProps = 'row') => `
  display: flex;
  ${flexDirection(direction)}
`;

export const flexDirection = (
  direction: 'row' | 'column' | 'row-reverse' | 'column-reverse' = 'row',
) => `
  flex-direction: ${direction};
`;

export const flexWrap = (wrap: 'nowrap' | 'wrap' | 'wrap-reverse') => `
  flex-wrap: ${wrap};
`;

export type AlignItemsProps =
  | 'flex-start'
  | 'start'
  | 'end'
  | 'flex-end'
  | 'center'
  | 'baseline'
  | 'stretch';
export const alignItems = (align: AlignItemsProps) => `
  align-items: ${align};
`;

export type AlignSelfProps =
  | 'center'
  | 'start'
  | 'end'
  | 'baseline'
  | 'safe center'
  | 'stretch'
  | 'flex-start'
  | 'flex-end'
  | 'auto';

export const alignSelf = (align: AlignSelfProps) => `
  align-self: ${align};
`;

export type JustifyContentProps =
  | 'flex-start'
  | 'flex-end'
  | 'center'
  | 'space-between'
  | 'space-around'
  | 'space-evenly';

export const justifyContent = (justify: JustifyContentProps) => `
  justify-content: ${justify};
`;

export const justifyItems = (
  justify:
    | 'auto'
    | 'normal'
    | 'stretch'
    | 'center'
    | 'start'
    | 'end'
    | 'flex-start'
    | 'flex-end'
    | 'baseline',
) => `
  justify-items: ${justify};
`;

export const justifySelf = (
  justify:
    | 'center'
    | 'start'
    | 'end'
    | 'self-start'
    | 'self-end'
    | 'flex-start'
    | 'flex-end'
    | 'right'
    | 'left',
) => `
  justify-self: ${justify};
`;

export const gridArea = (area: string) => `
  grid-area: ${area};
`;

export const gridTemplateAreas = (
  area: 'initial' | 'inherit' | 'none' | 'unset' | string,
) => `
  grid-template-areas: ${area};
`;

export const gridColumn = (column: string) => `
  grid-template-columns:${column};
`;

export const gridRow = (row: string) => `
  grid-template-rows: ${row};
`;

export const position = (
  position: 'static' | 'absolute' | 'fixed' | 'relative' | 'sticky',
) => `
  position: ${position};
`;

export const float = (float: 'left' | 'right' | 'none') => `
  float: ${float};
`;

export const minWidth = (width: string) => `
  min-width: ${width};
`;

export const minHeight = (height: string) => `
  min-height: ${height};
`;

// left is modal centering
export type ModalSizeType = number | keyof ModalSize;
export const modalSize = (size: ModalSizeType) => `
  width: ${
    typeof size === 'number' ? `${size}px` : `${theme.modalSize[size]}px`
  };
  left: calc(50% - ${
    typeof size === 'number' ? size / 2 : theme.modalSize[size] / 2
  }px);
`;
/** Ending of Display Helpers */

/** Beginning of Spacing Helpers */
export type SpacingType = keyof Spacing | number;
const spacing = (space: SpacingType) => {
  const { spacing } = theme;
  if (typeof space !== 'number') return spacing[space];

  return `${space}px`;
};

export const padding = (padding: SpacingType) => `
  ${paddingX(padding)}
  ${paddingY(padding)}
`;

export const paddingX = (padding: SpacingType) => `
  padding-left: ${spacing(padding)};
  padding-right: ${spacing(padding)};
`;

export const paddingL = (padding: SpacingType) => `
  padding-left: ${spacing(padding)};
`;

export const paddingR = (padding: SpacingType) => `
  padding-right: ${spacing(padding)};
`;

export const paddingY = (padding: SpacingType) => `
  padding-top: ${spacing(padding)};
  padding-bottom: ${spacing(padding)};
`;

export const paddingT = (padding: SpacingType) => `
  padding-top: ${spacing(padding)};
`;

export const paddingB = (padding: SpacingType) => `
  padding-bottom: ${spacing(padding)};
`;

export const margin = (margin: SpacingType) => `
  ${marginX(margin)}
  ${marginY(margin)}
`;

export const marginX = (margin: SpacingType) => `
  margin-left: ${spacing(margin)};
  margin-right: ${spacing(margin)};
`;

export const marginL = (margin: SpacingType) => `
  margin-left: ${spacing(margin)};
`;

export const marginR = (margin: SpacingType) => `
  margin-right: ${spacing(margin)};
`;

export const marginY = (margin: SpacingType) => `
  margin-top: ${spacing(margin)};
  margin-bottom: ${spacing(margin)};
`;

export const marginT = (margin: SpacingType) => `
  margin-top: ${spacing(margin)};
`;

export const marginB = (margin: SpacingType) => `
  margin-bottom: ${spacing(margin)};
`;

export const gridGap = (gap: SpacingType) => `
  grid-gap: ${spacing(gap)};
`;

export const flexGap = (gap: SpacingType) => `
  gap: ${spacing(gap)};
`;

export const gridRowGap = (rowGap: SpacingType) => `
  grid-row-gap: ${spacing(rowGap)};
`;

export const gridColumnGap = (columnGap: SpacingType) => `
  grid-column-gap: ${spacing(columnGap)};
`;
/** Ending of Spacing Helpers */

/** Beginning of Color Helpers */

// Transparentize is working as how much you want to transparentize
// so if you give 0.2 it will make 0.8 so making 1-opacity
// makes the function returns the opacity you want not how much
export type ColorProps = keyof Color | [keyof Color, number];
// 2nd prop on array is opacity that you want for the color
// red,0.1 gives you opacity 0.1 to red color
// color and background has same ability to do so

export const color = (color: ColorProps) => {
  if (typeof color === 'string') {
    // Checking if a string which is keyOf Color
    return `color: ${theme.color[color]};`;
  }
  if (Array.isArray(color)) {
    // Checking if its passed with opacity for color
    const [c, opacity] = color;

    return `color: ${transparentize(1 - opacity, theme.color[c])};`;
  }

  return '';
};

export const background = (color: ColorProps) => {
  if (typeof color === 'string') {
    return `background-color: ${theme.color[color]};`;
  }
  if (Array.isArray(color)) {
    const [c, opacity] = color;

    return `background-color: ${transparentize(1 - opacity, theme.color[c])};`;
  }

  return '';
};
/**
 * TODO
 * background image needs some update
 * for linear gradient part */
export const backgroundImage = (
  image: string,
  size: 'cover' | 'contain' | [string, string],
  repeat: 'repeat' | 'repeat-x' | 'repeat-y' | 'no-repeat',
  position = '',
) => `
  background-image: url(${image}); 
  background-size: ${Array.isArray(size) ? size.join(' ') : size};
  background-repeat: ${repeat}; 
  background-position: ${position};
`;

/** Beginning of Border Helpers */
export const radius = (radius: keyof BorderRadius) => `
  border-radius: ${theme.borderRadius[radius]};
`;

export const border = (border: number) => `
  border: ${theme.borders[border]};
`;

export const borderL = (border: number) => `
  border-left: ${theme.borders[border]};
`;

export const borderB = (border: number) => `
  border-bottom: ${theme.borders[border]};
`;

export const borderT = (border: number) => `
  border-top: ${theme.borders[border]};
`;

export const borderRadiusTL = (radius: keyof BorderRadius) => `
  border-top-left-radius: ${theme.borderRadius[radius]};
`;

export const borderRadiusTR = (radius: keyof BorderRadius) => `
  border-top-right-radius: ${theme.borderRadius[radius]};
`;

export const borderRadiusBL = (radius: keyof BorderRadius) => `
  border-bottom-left-radius: ${theme.borderRadius[radius]};
`;

export const borderRadiusBR = (radius: keyof BorderRadius) => `
  border-bottom-right-radius: ${theme.borderRadius[radius]};
`;

export const borderColor = (color: keyof Color) => `
  border-color: ${theme.color[color]};
`;
/** Ending of Border Helpers */

/** Beginning of Shadow Helpers */
export const shadow = (shadow?: keyof Shadows) => `
  box-shadow: ${shadow ? theme.shadows[shadow] : ''};
`;
/** Ending of Shadow Helpers */

/** Beginning of Text Helpers */
export const font = (family: keyof FontFamily) => `
  font-family: ${theme.fontFamily[family]};
`;

export const fontSize = (size: keyof FontSizes | number) => `
  font-size: ${typeof size !== 'number' ? theme.fontSize[size] : `${size}px`};
`;

export const lineHeight = (height: keyof LineHeight) => `
  line-height: ${theme.lineHeight[height]};
`;

export type FontStyleProps =
  | 'normal'
  | 'italic'
  | 'oblique'
  | 'initial'
  | 'inherit';
export const fontStyle = (style: FontStyleProps) => `
  font-style: ${style};
`;

export const iconSize = (size: keyof IconSizes) => `
  font-size: ${theme.iconSizes[size]};
`;

export type TextTransformProps =
  | 'capitalize'
  | 'uppercase'
  | 'lowercase'
  | 'initial'
  | 'inherit';
export const textTransform = (transform: TextTransformProps) => `
  text-transform: ${transform};
`;

export type TextAlignProps =
  | 'left'
  | 'right'
  | 'center'
  | 'justify'
  | 'initial'
  | 'inherit';
export const textAlign = (align: TextAlignProps) => `
  text-align: ${align};
`;

export const fontWeight = (weight: keyof FontWeight) => `
  font-weight: ${theme.fontWeight[weight]};
`;

export type WhiteSpaceProps =
  | 'normal'
  | 'nowrap'
  | 'pre'
  | 'pre-line'
  | 'pre-wrap'
  | 'initial'
  | 'inherit';
export const whitespace = (whitespace: WhiteSpaceProps) => `
  white-space: ${whitespace};
`;

export const truncateText = () => `
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const textIndent = (indent: SpacingType) => `
  text-indent: ${spacing(indent)};
`;

export const letterSpacing = (spacing: string) => `
  letter-spacing: ${spacing};
`;
/** Ending of Text Helpers */

/** Beginning of Z-Index Helpers */
export const zIndex = (index: keyof ZIndices) => `
  z-index: ${theme.zIndices[index]};
`;
/* Ending of Z-Index Helpers */

/** Beginning Custom ScrollBar */
export const customScrollBar = `
::-webkit-scrollbar {
  width: 14px;
}
::-webkit-scrollbar-button {
  width: 0px;
  height: 0px;
}
::-webkit-scrollbar-thumb {
  background: #301e35;
  border-radius: 25px;
  border: 4px solid white;
}
::-webkit-scrollbar-track:hover,
::-webkit-scrollbar-track:active,
::-webkit-scrollbar-corner {
  border: 2px solid white;
}
`;
/** Ending of Custom ScrollBar */
